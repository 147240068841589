import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class NotificationsAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  formSubmitted:any;
  ngOnInit() {
    this.getType();
    
    this.getUsers();
    // this.getDealers();
    // this.getArchitects();
    // this.getCarpenters();
    
    this.form.remark='';
  }
  
  searchUser:any={};
  usersData:any={};
  getUsers(){
    // this.db.presentLoader();
    this.db.postReq({filter:{name:this.searchUser.users},limit : 50,start : 0},'dis_network/sales_user').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.usersData.users = resp['result']['data'];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
  }
  getDealers(){
    
    // this.db.presentLoader();
    this.db.postReq({filter:{company_name:this.searchUser.dealer,cust_type_id:2},limit : 50,start : 0},'dis_network/list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.usersData.dealers = resp['result']['data'];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  getArchitects(){
    
    // this.db.presentLoader();
    this.db.postReq({filter:{name:this.searchUser.architect,cust_type_id:4},limit : 50,start : 0},'dis_network/list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.usersData.architects = resp['result']['data'];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  getCarpenters(){
    
    // this.db.presentLoader();
    this.db.postReq({filter:{name:this.searchUser.carpenter,cust_type_id:3},limit : 50,start : 0},'dis_network/list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.usersData.carpenters = resp['result']['data'];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  typeData:any=[];
  getType(){
    this.db.presentLoader();
    this.db.postReq({},'notification/type').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.typeData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  
  form: any={};
  formsInit(data:any) {
  }
  
  onSubmit(data:any){
    // if(this.form.invalid){
    //   this.form.controls['title'].markAsTouched();
    //   this.form.controls['remark'].markAsTouched();
    //   return
    // }
    this.form.customerIds=[];
    this.form.userIds=[];
    
    this.usersData.users.map(async r=>{
      if(r.checked == true){
        await this.form.userIds.push({user_id:r.id})
      }
    })
    // this.usersData.dealers.map(async r=>{
    //   if(r.checked == true){
    //     await this.form.customerIds.push({customer_id:r.id})
    //   }
    // })
    // this.usersData.carpenters.map(async r=>{
    //   if(r.checked == true){
    //     await this.form.customerIds.push({customer_id:r.id})
    //   }
    // })ß
    // this.usersData.architects.map(async r=>{
    //   if(r.checked == true){
    //     await this.form.customerIds.push({customer_id:r.id})
    //   }
    // })
    this.form.type='sfa';
    this.form.image = this.form.image_path;
    this.db.presentLoader();
    this.db.postReq(this.form, 'notification/send').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  changeListener($event): void {
    console.log($event);
    this.form.image_path_loading = true;
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.image_path_loading = false;
          this.form.image_path = resp['response'].fileName;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.form.image_path_loading = false;
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  selectAll:any={};
  selectAllHandler(type){
    
    if(this.selectAll[type] == true){
      this.usersData[type].map(r=>{
        r.checked = true;
      })
    }else{
      this.usersData[type].map(r=>{
        r.checked = false;
      })
    }
    console.log(type,this.selectAll,this.usersData);
    
  }
}
