import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatabaseService } from './database.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  private dataUrl = 'assets/hierarchy-data.json';
  
  constructor(private http: HttpClient, public db : DatabaseService) { 
  }
  
  getHierarchyData(): Observable<any> {
    return this.http.get<any>(this.dataUrl);
  }
  
  apiUrl:any;
  header:any = new HttpHeaders();
  getHierarchyDataV2(payload: any): Observable<any[]> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': 'Bearer admin-1' // Replace with your actual token
    });
    
    this.apiUrl = this.db.DbUrl + 'user_heirarchy/list';
    return this.http.post<any>(this.apiUrl, payload, { headers }).pipe(
      map(response => response.data) // Adjust this according to the API response structure
    );
  }
  
  
  getChildrenData(parentId: number): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': 'Bearer admin-1' // Replace with your actual token
    });
    this.apiUrl = this.db.DbUrl + 'user_heirarchy/list/'+parentId;
    return this.http.post<any>(this.apiUrl, {}, { headers }).pipe(
      map(response => response.data) // Adjust this according to the API response structure
    );
  }
}
