import { Component, OnInit,Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup,FormControl, Validators } from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-beat-plan-detail-model',
  templateUrl: './beat-plan-detail-model.component.html',
})
export class BeatPlanDetailModelComponent implements OnInit {
  gallery:any=[];
  beatData:any= {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public db:DatabaseService,public activatedroute:ActivatedRoute,private _location: Location) { 
    this.formsInit(this.data);
    console.log("data===>",data)
    this.beatData = data;
  }
  
  form: FormGroup;
  formsInit(data:any) {
    console.log(this.data);
    this.form = this.db.formBuilder.group({
      beat_data: [data.beat_data ? data.beat_data : null ,[Validators.required]],
      beat_plan_date: [data.beat_plan_date ? data.beat_plan_date : null ,[Validators.required]],
      description: [data.description ? data.description : null ],
      user_name: [data.name ? data.name : null ],
      user_id: [data.user_id ? data.user_id : null ,[Validators.required]],
      user_role_name: [data.role_name ? data.role_name : null],
      user_emp_code: [data.emp_code ? data.emp_code : null],
    })
    console.log(this.form.value);
  }
  
  ngOnInit() {
    this.getBeatList();
  }
  
  onSubmit() {
    // console.log("cdscad",this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value, 'beat_plan/add_beat').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        // this.backClicked();
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
  }
  
  beatListData:any=[];
  filteredBeat:any={};
  getBeatList(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/beat_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.beatListData = resp['result'].data;
        console.log("getBeatList==>",this.filteredBeat)
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      console.log('err',err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
}
