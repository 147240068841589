import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-complaint-status',
  templateUrl: './complaint-status.component.html',
})
export class ComplaintStatusComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {},public db:DatabaseService,
  private _location: Location) { }
// data:any = this.db.redeemRequestData
ngOnInit() {
  
  this.formsInit(this.data);
}

form: FormGroup;
formsInit(data:any) {
  console.log(data);
  
  this.form = this.db.formBuilder.group({
    status: [data.status ? data.status : null ,[Validators.required]],
    remark: [data.status_remark ? data.status_remark : null ,[]],
    complaint_id: [data.id ? data.id : 0 ,[Validators.required]],
  })
  console.log(this.form.value);
  
}
backClicked() {
  this._location.back();
}

onSubmit(){
  if(this.form.invalid){
    this.form.markAllAsTouched()
    return;
  }
  let reqData = this.form.value
  this.db.presentLoader();
  this.db.postReq(reqData,'complaint/update_status').subscribe(resp=>{
    this.db.dismissLoader();
    if(resp['status'] == 'success'){
      this.db.successAlert(resp['status'],resp['message']);
      this.db.matdialog.closeAll();
    }else{
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      this.db.presentAlert(resp['status'],resp['message'])
    }
  },err=>{
    this.db.errHandler(err);
    this.db.errDismissLoader();
  })
  
}


}
