import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-complaint-module-add',
  templateUrl: './complaint-module-add.component.html',
})
export class ComplaintModuleAddComponent implements OnInit {

  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    // this.getProducts('');
    this.getDrType();
    this.getDrData('');
    // this.getBrand();
    this.getCategory();
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'complaint_id':id},'complaint_module_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
        this.getProducts(resp['data']['detail'].brand);
        this.getBrand(resp['data']['detail'].category);
        

      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      dr_type : [this.actRoute.snapshot.params.id ? data.cust_type_id : null ,[Validators.required]],
      customer : [this.actRoute.snapshot.params.id ? data.customer_id : null ,[Validators.required]],
      product : [this.actRoute.snapshot.params.id ? data.product_id : null ,[Validators.required]],
      description : [this.actRoute.snapshot.params.id ? data.description : null ,[]],
      select_effect_on_patient : [this.actRoute.snapshot.params.id ? data.select_effect_on_patient : null ,[]],
      effect_on_patient : [this.actRoute.snapshot.params.id ? data.effect_on_patient : null ,[]],
      product_using: [this.actRoute.snapshot.params.id ? data.product_using : null ,[]],
      earlier_used_product: [this.actRoute.snapshot.params.id ? data.earlier_used_product : null ,[]],
      no_of_piece: [this.actRoute.snapshot.params.id ? data.no_of_piece : null ,[]],
      faced_same_issue_earlier: [this.actRoute.snapshot.params.id ? data.faced_same_issue_earlier : null ,[]],
      who_reported: [this.actRoute.snapshot.params.id ? data.who_reported : null ,[]],
      when_reported: [this.actRoute.snapshot.params.id ?  moment(data.when_reported).format('YYYY-MM-DD') : null ,[]],
      when_defect_observe: [this.actRoute.snapshot.params.id ? data.when_defect_observe : null ,[]],
      sample_tried_other_location: [this.actRoute.snapshot.params.id ? data.sample_tried_other_location : null ,[]],
      response_letter: [this.actRoute.snapshot.params.id ? data.response_letter : null ,[]],
      replacement: [this.actRoute.snapshot.params.id ? data.replacement : null ,[]],
      complaint_id: [this.actRoute.snapshot.params.id ? this.actRoute.snapshot.params.id : 0],
      batch_no : [this.actRoute.snapshot.params.id ? data.batch_no : null ,[]],
      mfd : [this.actRoute.snapshot.params.id ?  moment(data.mfd).format('YYYY-MM-DD') : null ,[]],
      exd : [this.actRoute.snapshot.params.id ? moment(data.exd).format('YYYY-MM-DD') : null ,[]],
      defective_sample : [this.actRoute.snapshot.params.id ? data.defective_sample : null ,[]],
      suggestion : [this.actRoute.snapshot.params.id ? data.suggestion : null ,[]],
      brand : [this.actRoute.snapshot.params.id ? data.brand : null ,[]],
      category : [this.actRoute.snapshot.params.id ? data.category : null ,[]],
      complaint_doc : [this.actRoute.snapshot.params.id && data.complaint_doc ? data.complaint_doc.replace(this.db.s3path,'') : null ,[]],
      image_10 : [this.actRoute.snapshot.params.id && data.complaint_doc ? data.complaint_doc.replace(this.db.s3path,'') : null ,[]],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['dr_type'].markAsTouched();
      this.form.controls['customer'].markAsTouched();
      // this.form.controls['description'].markAsTouched();
      this.form.controls['product'].markAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'update_complaint_module' : 'add_complaint_module').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
      if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }

  drType:any = [];
  getDrType(){

    let reqData = {
      limit : 100,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"checkin/dr_type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drType = resp['result'].data;
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })

  }

  custData:any = [];
  getDrData(data:any) {
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {cust_type_id:data ? data.id : ''}
    }
    console.log("-->",data)
    this.db.presentLoader();
    this.db.postReq(reqData,'checkin/assigned/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['data'].drData;
        console.log('this.custData--->',this.custData)
        // this.form.controls['customer'].setValue(this.custData[0]);
        // // this.filteredCustData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  brand:any = [];
  getBrand(category_name) {
    let reqData = {
      limit : 1000000,
      start : 0,
      filter : {},
      category_name : category_name

    }
    this.db.presentLoader();
    this.db.postReq(reqData,"master/category/assigned_brand").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['status']=='success'){
        this.brand = resp['result'].data;
        console.log(this.brand);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  category:any = [];
  getCategory() {
    let reqData = {
      limit : 1000000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"master/product_category").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['status']=='success'){
        this.category = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  product:any = [];
  getProducts(brand) {
    let reqData = {
      limit : 1000000,
      start : 0,
      filter : {brand:brand}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"master/product_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['status']=='success'){
        this.product = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  changeListener($event): void {
      
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['complaint_doc'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }

}
