import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { StatusModelComponent } from 'src/app/leads/status-model/status-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-notification-detail-model',
  templateUrl: './notification-detail-model.component.html'
})

export class NotificationDetailModelComponent implements OnInit {
  gallery:any=[];
 
   data:any=[];
   dataNotFound=false
   reqSent=false
   
   
   typeName:any 
   constructor(
     public dialog: MatDialog,
     public db:DatabaseService,
     private render: Renderer2,
     @Inject(MAT_DIALOG_DATA) public notificationData:any 
     ) {  
       
     }
     ngOnInit() {
       this.filter.gift_status='';
       this.filter.redeem_status = '';
       this.getData();
       this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
     }
     tabActiveType:any={};
     tabActive:any='All'
     todayDate :any
     pageNo:any = 1;
     tempPageNo = 1;
     totalPages:any
     start:any = 0;
     limit:any = 50;
     totalLength:any;
     filter:any={};
     getData(){
      this.filter.notification_code = this.notificationData.notification_code
       this.dataNotFound = false;
       this.reqSent = false;
       this.filter.statusName = '';
       let reqData = {
         limit : this.limit,
         start : this.start,
         filter : this.filter
       }
       this.db.presentLoader();
       this.db.postReq(reqData,'notification/detail').subscribe(resp=>{
         this.reqSent = true;
         if(resp['status'] == 'success'){
           this.data = resp['result'].data;
           if(!this.data.length) this.dataNotFound = true;
           this.totalLength = resp['result'].totalCount;
           this.totalPages = Math.ceil(this.totalLength / this.limit);
         }else{
           if(resp['message']=='Invalid token'){
             this.db.sessionExpire();
             return;
           }
           this.db.presentAlert(resp['status'],resp['message'])
         }
       },err=>{
         
       })
     }
  
     pagination(action){
       
       if(action == 'pageNo'){
         if(this.pageNo > 0 && this.pageNo <=this.totalPages){
           this.start = this.limit*(this.pageNo-1)
         }else{
           this.pageNo = Math.ceil(this.totalLength / this.data.length);
         }
       }
       else if(action=='next'){
         
                if(this.totalLength == (this.start+this.data.length)) return;

         this.start = this.start+this.limit
         this.pageNo ++ ;
       }else{
         if(this.pageNo == 1) return
         this.start = this.start+this.limit
         this.pageNo -- ;
       }
       this.getData();
       this.tempPageNo = this.pageNo
     }
     refresh(){
       this.start = 0;
       this.limit = 50;
       this.filter = {};
       this.getData();
     }
     // delete(data){
     //   swal({
     //     title: "Are you sure?",
     //     text: "You want to delete this "+this.typeName+"!",
     //     icon: "warning",
     //     buttons: ["Cancel", "Confirm"],
     //     dangerMode: true,
     //   })
     //   .then((willDelete) => {        
     //     if (willDelete) {
     //       this.db.presentLoader();
     //       data.customer_id = data.encrypt_id;
     //       this.db.postReq(data,'dis_network/delete').subscribe(resp=>{
     //         if(resp['status'] == 'success'){
     //           this.getData();
     //           swal("Deleted", {
     //             icon: "success",
     //           });
     //         }else{
     //           if(resp['message']=='Invalid token'){
     //             this.db.sessionExpire();
     //             return;
     //           }
     //           this.db.presentAlert(resp['status'],resp['message'])
     //         }
     //       })
     
     //     } else {
     //       swal("Your data is safe!");
     //     }
     //   });
     // }
     
     status:boolean = false
     
     toggleNav() 
     {
       this.status = !this.status;
       if(this.status) {
         this.render.addClass(document.body, 'toggle-active-tlp');
       }
       else {
         // this.render.removeClass(document.body, 'toggle-active-tlp');
       }
     }
     openStatusDialog(data): void {
       console.log(data);
       
       const dialogRef = this.dialog.open(StatusModelComponent, {
         width: '400px',
         autoFocus: false,
         data: data
       });
       
       dialogRef.afterClosed().subscribe(result => {
         this.getData();     
       });
     }
     // loginStatusChange(data){
     //   swal({
     //     title: "Are you sure?",
     //     text: data.login_status == 1  ? "You want to make this Activate Login!" : "You want to make this Inactivate Login!",
     //     icon: "warning",
     //     buttons: ["Cancel", "Confirm"],
     //     dangerMode: true,
     //   })
     //   .then((willDelete) => {        
     //     if (willDelete) {
     //       this.db.presentLoader();
     //       data.login_status = data.login_status == 0 ? 1 : 0;
     //       data.customer_id = data.encrypt_id;
     //       this.db.postReq(data,'dis_network/login_status').subscribe(resp=>{
     //         if(resp['status'] == 'success'){
     //           swal(data.login_status == 0 ? "Inactive!" : "Active", {
     //             icon: "success",
     //           });
     //           this.getData();
     //         }else{
     //           if(resp['message']=='Invalid token'){
     //             this.db.sessionExpire();
     //             return;
     //           }
     //           this.db.presentAlert(resp['status'],resp['message'])
     //         }
     //       })
           
     //     } else {
     //       swal("Your data is safe!");
     //     }
     //   });
     // }
     selectAll(event){
       this.data.map(r=>{
         if(event.checked == true){
           r.checked = true;
         }else{
           r.checked = false
         }
       })
     }
     deleteAll(){
       let arr=[];
       this.data.map(async r=>{
         if(r.checked == true){
           await arr.push(r.id)
         }
       })
       if(!arr.length){
         this.db.presentAlert('Error','Please select items first');   
         return; 
       }
       swal({
         title: "Are you sure?",
         text: "You want to delete this",
         icon: "warning",
         buttons: ["Cancel", "Confirm"],
         dangerMode: true,
       })
       .then((willDelete) => {        
         if (willDelete) {
           this.db.presentLoader();
           
           this.db.postReq({couponIdArray:arr},'coupon/delete_multiple').subscribe(resp=>{
             if(resp['status'] == 'success'){
               this.getData();
               swal("Deleted", {
                 icon: "success",
               });
             }else{
               if(resp['message']=='Invalid token'){
                 this.db.sessionExpire();
                 return;
               }
               this.db.presentAlert(resp['status'],resp['message'])
             }
           })
           
         } else {
           swal("Your data is safe!");
         }
       });
     }
     downloadExcel(){
       this.dataNotFound = false;
       this.reqSent = false;
       this.filter.statusName = this.tabActive == 'All' ? '' : this.tabActive;
       let reqData = {
         filter : this.filter
       }
       this.db.presentLoader();
       this.db.postReq(reqData,'coupon/list/excel').subscribe(resp=>{
         this.reqSent = true;
         if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
         }else{
           if(resp['message']=='Invalid token'){
             this.db.sessionExpire();
             return;
           }
           this.db.presentAlert(resp['status'],resp['message'])
         }
       },err=>{
         
       })
       
     }
   }
   
   
   
   