import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-city-add',
  templateUrl: './city-add.component.html',
})
export class CityAddComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public db:DatabaseService,
    private _location: Location) { }
  // data:any = this.db.redeemRequestData
  ngOnInit() {
    this.db.getCountry();
    this.formsInit(this.data);
  }
  
  form: FormGroup;
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      country: [data.country ? data.country : null ,[Validators.required]],
      state_name: [data.state_name ? data.state_name : null ,[Validators.required]],
      district_name : [data.district_name ? data.district_name : null ,[Validators.required]],
      city: [data.city ? data.city : '' ,[Validators.required]],
    })
    console.log(this.form.value);
    
  }
  backClicked() {
    this._location.back();
  }
  
  onSubmit(){console.log('yes');
  
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    this.db.presentLoader();
    this.db.postReq(reqData,'master/add_city').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
}
