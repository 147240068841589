import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert';
import { DatabaseService } from '../_services/database.service'; 
import * as moment from 'moment';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-vs-visit',
  templateUrl: './customer-vs-visit.component.html',
})
export class CustomerVsVisitComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  checkModulePermission:any = {}
  
  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService,
    public activatedroute:ActivatedRoute
    ) {  
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);

      this.event.subscribe('refresh-travel-vs-visit', (data: any) => {
        // this.type =this.activatedroute.snapshot.params.type
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        console.log('refresh');
        
      })
    }
    ngOnInit() {
      this.filter.status = this.tabActive;
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD');
      // this.getsalesData();
    }
    showLoading:any=false;
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    checkinData:any = [];
    travelData:any = [];
    travelVsVisitData = []
    dataObject:any = {}
    parse:any = {}
    getData(){
      this.showLoading = true;
      this.dataNotFound = false;
      this.reqSent = false;
      // this.filter.search = null
      this.filter.status =  this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'customer_network/visit').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.travelVsVisitData = resp['result'].data;
          this.showLoading = false;
          if(!this.checkinData.length && !this.travelData.length) this.dataNotFound = true; 
        }else{
          this.showLoading = false;
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    

    getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

    backClicked() {
      this._location.back();
    } 
    status:boolean = false
  
    downloadExcel(){
      this.filter.gift_status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'leave/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
}
