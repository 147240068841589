import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { RemarkModelComponent } from 'src/app/remark-model/remark-model.component';
import * as moment from 'moment';
import { GiftStatusModelComponent } from 'src/app/redeem-req/gift-status-model/status-model.component';
import { RedeemRequestStatusModelComponent } from 'src/app/redeem-req/redeem-status-model/status-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { ExpenseStatusModelComponent } from 'src/app/expense/status-modal/status-model.component';
import { TravelStatusModelComponent } from '../status-modal/status-model.component';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';
import { TravelListModalComponent } from '../travel-list-modal/travel-list-modal.component';



@Component({
  selector: 'app-travel-list',
  templateUrl: './travel-list.component.html'
})
export class TravelListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  showLoading:any=false;
  checkModulePermission:any = {};

  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService,
    public activatedroute:ActivatedRoute,
    ) {  
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);
      
      this.event.subscribe('refresh-travel-plan-list', (data: any) => {
        // this.type =this.activatedroute.snapshot.params.type
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        this.getCounters();
        console.log('refresh');
      });
      
    }
    
    
    ngOnInit() {
      // this.db.getStates();
      this.generateMonthYearArray();
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      this.filter.status = this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      let reqData = {
        getAssignedUserData : this.db.getAssignedUserData
      }

      this.db.postReq(reqData,'travel_plan/list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          // resp['result'].data.map(r=>{
          this.counters= resp['result'].data
          // })
          console.log('this.counters',this.counters);
          // this.tabActive = resp['result'].data[0].name; 
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
        if(this.totalLength == (this.start+this.data.length)) return;
        
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        // this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    openStatusDialog(data:any): void {
      console.log('openStatusDialog',data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(TravelStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
    openGiftDialog(data): void {
      console.log(data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(GiftStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
    
    downloadExcel(){
      this.filter.gift_status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    delete(data){
      console.log(data);
      // return
      let reqData = {
        user_id : data.user_id,
        travel_from : data.travel_from
      }
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          this.db.postReq(reqData,'travel_plan/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Deleted", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    
    result:any = [];
    generateMonthYearArray() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); 
      const currentYear = currentDate.getFullYear();
      this.result = [];
      
      for (let i = -2; i < 4; i++) {
        let monthIndex = (currentMonth + i) % 12;
        let year = currentYear + Math.floor((currentMonth + i) / 12);
        // console.log(monthIndex,'---',year);
        
        if (monthIndex < 0) {
          monthIndex += 12;
          // year--;
        }
        const monthYearString = `${months[monthIndex]} ${year}`;
        this.result.push(monthYearString);
      }
    }
    
    
    monthYear = '';
    month:any;
    year:any;
    getExcelReport(){
      
      if (this.monthYear == '') {
        this.db.presentAlert('Please Select Month','You can Download report once you selected the month');
        this.showLoading = false;
        return
      }
      
      var monthMap = {
        'Jan': 1,
        'Feb': 2,
        'Mar': 3,
        'Apr': 4,
        'May': 5,
        'Jun': 6,
        'Jul': 7,
        'Aug': 8,
        'Sep': 9,
        'Oct': 10,
        'Nov': 11,
        'Dec': 12
      };
      
      var dateComponents = this.monthYear.split(' ');
      this.month = monthMap[dateComponents[0]];
      this.year = parseInt(dateComponents[1]);
      let reqData = {
        year : this.year,
        month : this.month,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      // console.log('reqData',reqData);
      // return
      
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.showLoading = false;
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.showLoading = false;
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.presentAlert('Error','Error Occured')
        this.showLoading = false;
      })
    }

    openDetail(row): void {
      console.log(row);
      
      const dialogRef = this.dialog.open(TravelListModalComponent, {
        width: '768px',
        autoFocus: false,
        data: row
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    selectAll(event){
      this.data.map(r=>{
        if(event.checked == true){
          r.checked = true;
        }else{
          r.checked = false
        }
      })
    }
  }
  
  
  
  
  
  