import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';

@Component({
  selector: 'app-check-in-add',
  templateUrl: './check-in-add.component.html',
})
export class CheckInAddComponent implements OnInit {
  showLoading:any=false;
  constructor(private _location: Location,public db:DatabaseService,public activatedroute:ActivatedRoute) { }
  
  ngOnInit() {
    this.getsalesData();
    this.getData();
    // this.getContactPerson();
    // this.getWorkingWith();
    this.getDrData('','');
    this.getProductDiscussion();
    this.getComplaint();
    if(this.activatedroute.snapshot.params.id){
      this.callFunctionWithTimeout(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
      this.form.controls['sales_user'].setValue(JSON.parse(localStorage.getItem('hmdUserData')).id);
    }
    this.getAssignedUserData();
    
     
  }
  drType:any=[];

  filter:any={};
  custData:any=[]
  // filteredCustData:any=[]

  data:any={}
  filteredData:any={};
  getData(){

    let reqData = {
      limit : 100,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"checkin/dr_type").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.drType = resp['result'].data;
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  teamMember:any = []
  getWorkingWith() {
    let reqData = {
      limit : 100,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"joint_working/sales_user_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        // this.teamMember = resp['result'].data;
        resp['data'].assignedUser.map (r=> {
          this.teamMember.push(r)
        })
        resp['data'].reportingUser.map (x=> {
          this.teamMember.push(x)
        })
        console.log('this.teamMember',this.teamMember);
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  getDrData(data:any,sales_user:any) {
    let reqData = {
      limit : 100,
      start : 0,
      filter : {cust_type_id:data ? data.id : '',sales_user : sales_user ? sales_user : ''}
    }
    console.log("-->",data,sales_user)
    this.db.presentLoader();
    this.db.postReq(reqData,'checkin/assigned/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['data'].drData;
        console.log('this.custData--->',this.custData)
        // this.form.controls['customer'].setValue(this.custData[0]);
        // // this.filteredCustData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  contactPerson:any = []
  getContactPerson(data) {
    console.log('data',data);
    
    let reqData = {
      limit : 100,
      start : 0,
      filter : {customer_id: data},
      customer_id: data
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/contact_detail').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.contactPerson = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  callFunctionWithTimeout(id) {
    setTimeout(() => {
      this.getDetail(id);
    }, 3000);
  }
   getDetail(id){

    this.showLoading = true;
    this.db.presentLoader();
    this.db.postReq({'checkin_id':id},'checkin/detail').subscribe( resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']);
        
        
        // setTimeout(async () => {
          var dr_type;
          this.drType.map(r=>{         
            if (r.id == resp['data'].detail.cust_type_id) {
              this.form.controls['dr_type'].setValue(r);
              dr_type = r;
            }
          })
          console.log('this.salesData-->',this.salesData)
          
          var user_id;
          if (this.salesData) {            
            this.salesData.map(r=>{         
              
              
              if (r.user_id == resp['data'].detail.user_id) {
                console.log(r.user_id,'---',resp['data'].detail.user_id);
                this.form.controls['sales_user'].setValue(r.user_id);
                // user_id = r.id
              }
            })
          }
         
          this.getContactPerson(resp['data'].detail.customer_id);
          this.form.controls['customer'].setValue(resp['data'].detail.customer_id);

          var productDiscussion = [];
          var contactPerson = [];
          var teamMember = [];
          if (this.productDiscussion) {
            this.productDiscussion.map(r=>{         
              resp['data'].productDiscussion.map(x=> {
                if (r.name == x.product_name) {
                  productDiscussion.push(r.id)
                  
                }
              })
            })
            console.log('productDiscussion',productDiscussion);
          }
          
          
          resp['data'].contactPerson.map(c=> {
            contactPerson.push(c.name);
          })

          resp['data'].teamMember.map(w=> {
            teamMember.push(w.user_id);
          })
          console.log('teamMember',teamMember);
          
          this.form.controls['image_path'].setValue(resp['data'].checkinDocument);
          
          this.getDrData( dr_type,user_id);
          console.log('---->',this.custData);
          
          this.form.controls['team_member'].setValue(teamMember);
          this.form.controls['product_discussion'].setValue(productDiscussion);
          this.form.controls['contact_person'].setValue(contactPerson);
          console.log('this.form.controls---->',this.form.controls['product_discussion']);
          
          

        // }, 100 * 1);
        this.showLoading = false;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
        this.showLoading = false;
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      sales_user : [data.user_id ? data.user_id : null , [Validators.required]],
      dr_type: [data.dr_type ? data.dr_type : null , [Validators.required]],
      customer: [data.customer ? data.customer : null , [Validators.required]],
      contact_person : [data.customer ? data.customer : null , []],
      checkin_date : [this.activatedroute.snapshot.params.id ? moment(data.detail.checkin_date).format('YYYY-MM-DD'): moment(new Date ()).format('YYYY-MM-DD')  , []],
      followup_date : [this.activatedroute.snapshot.params.id ? moment(data.followup.followup_date).format('YYYY-MM-DD'): '', []],
      followup_remark: [this.activatedroute.snapshot.params.id ? data.followup.description : null ,[]],
      product_discussion: [this.activatedroute.snapshot.params.id ? data.detail.product_discussion : null ,[]],
      product_discussion_remark : [this.activatedroute.snapshot.params.id ? data.detail.product_discussion_remark : null ,[]],
      collection_amount : [this.activatedroute.snapshot.params.id ? data.detail.collection_amount : null ,[]],
      collection_remark : [this.activatedroute.snapshot.params.id ? data.detail.collection_remark : null ,[]],
      complaint : [this.activatedroute.snapshot.params.id ? data.detail.complaint : null ,[]],
      complaint_remark : [this.activatedroute.snapshot.params.id ? data.detail.complaint_remark : null ,[]],
      checkin_id : [this.activatedroute.snapshot.params.id ? data.detail.id : null],
      followup_id : [this.activatedroute.snapshot.params.id ? data.followup.id : null],
      visit_doc : [this.activatedroute.snapshot.params.id && data.detail.visit_doc ? data.detail.visit_doc.replace(this.db.s3path,'') : null ,[]],
      image_10 : [this.activatedroute.snapshot.params.id && data.detail.visit_doc ? data.detail.visit_doc.replace(this.db.s3path,'') : null ,[]],
      followup_added : [this.activatedroute.snapshot.params.id ? data.detail.followup_added : null],
      team_member : [this.activatedroute.snapshot.params.id ? data.detail.team_member : null],
      image_path: [data.product_image ? data.product_image : [] ],
      image: [data.product_image ? data.product_image : null , []],
      image_path_loading:[false],
      order_product_name : [this.activatedroute.snapshot.params.id ? data.detail.order_product_name : null ,[]],
      quantity : [this.activatedroute.snapshot.params.id ? data.detail.quantity : null ,[]],
      product_discussion_detail : [this.activatedroute.snapshot.params.id ? data.orderProduct : [] ,[]],
    })
  }
  setStatus() {
    this.form.value.status = 'Draft';
    this.onSubmit
  }

  teamMemberData : any = []
  productData :any =[]
  onSubmit(){
    if(this.form.invalid){
    this.form.markAllAsTouched()
      return
    }
    this.form.value.checkin_date = this.form.value.checkin_date == 'Invalid date' ? '0000-00-00' : this.form.value.checkin_date;
    this.form.value.followup_date = this.form.value.followup_date == 'Invalid date' ? '0000-00-00' : this.form.value.followup_date;
    
    
    if (this.form.value.team_member) {
      this.form.value.team_member.map(r=>{
        this.assignedUserData.map(x=> {
          if (r == x.user_id) {
            this.teamMemberData.push(x);
          }
        })
      })
    }

    // console.log('this.form.value.product_discussion',this.form.value.product_discussion);
    // console.log('this.form.value.product_discussion',this.productDiscussion);

    
    if (this.form.value.product_discussion && this.form.value.product_discussion.length) {
      this.form.value.product_discussion.map(d=> {
        this.productDiscussion.map(p=> {
          if (d == p.id) {
            this.productData.push(p);
          }
        })
      })
    }
    
    this.form.value.teamMemberData = this.teamMemberData
    this.form.value.product_discussion = this.productData
    // console.log("FormValues",this.form.value);
    // return
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.activatedroute.snapshot.params.id ? 'checkin/update' : 'checkin/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }

  productDiscussion:any=[];
  getProductDiscussion(): void{
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/product_discussion').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.productDiscussion = resp['result'].data;
        this.selectAllForDropdownItems(this.productDiscussion);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  complaint:any=[];
  getComplaint(): void{
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/complaint').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.complaint = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  salesData:any=[];
  filteredSalesData:any=[];
  getsalesData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
      }
    }
    this.db.presentLoader();
      this.db.postReq(reqData,"get_junior/assignedUserList").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        if(resp['status']=='success'){
          this.salesData = resp['data'];
          this.selectAllForDropdownItems(this.salesData);
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        console.log('err',err);
      })  
  }

  // salesData:any=[];
    assignedUserData : any;
    getAssignedUserData() {
      
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
        }
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,"get_all/assignedUserList").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        if(resp['status']=='success'){
          this.assignedUserData = resp['data'];
          // console.log('this.salesData',this.salesData);
          
          // this.selectAllForDropdownItems(this.salesData);
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        console.log('err',err);
      })
    }

    productImage:any=[]
  changeListener($event): void {
    console.log($event);
    this.form.controls['image_path_loading'].setValue(true);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          // this.form.controls['image_path'].setValue(this.productImage);
          console.log('resp--->',resp['response'].fileName);
          this.form.value.image_path.push({filename:resp['response'].fileName,status:1})
         console.log("image",this.form.value.image_path)

        //  this.form.controls['image_path'].setValue(resp['response'].fileName);
         this.form.controls['image_path_loading'].setValue(false);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }

removeScheme(index){
  this.form.value.image_path.splice(index,1);
}

addProductDiscussionDetail() {
  if(!this.form.value.order_product_name || !this.form.value.quantity){
    this.db.presentAlert('Error','Please fill all the required details to continue!');
    return;
  }
  
  this.form.controls['product_discussion_detail'].setValue([...this.form.value.product_discussion_detail,{product_name:this.form.value.order_product_name, quantity:this.form.value.quantity}])
  this.form.controls['order_product_name'].setValue(null);
  this.form.controls['quantity'].setValue(null);
  // this.form.controls['collection_remark'].setValue(null);
  // this.form.controls['collection_amount'].setValue(null);
  
  console.log(this.form.value.product_discussion_detail);
}

deleteContact(row,index){
  console.log(index);
  swal({
    title: "Are you sure?",
    text: "You want to delete this!",
    icon: "warning",
    buttons: ["Cancel", "Confirm"],
    dangerMode: true,
  })
  .then((willDelete) => {        
    if (willDelete) {  
      this.form.controls['product_discussion_detail'].setValue(this.form.value.product_discussion_detail.filter((r,i)=> i != index ))
    } else {
      swal("Your data is safe!");
    }
  });
  // }
  
  
}
}
