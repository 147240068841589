import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import swal from 'sweetalert';
// import { AdvancedModeGraphModelComponent } from '../advanced-mode-graph-model/advanced-mode-graph-model.component';
import { DatabaseService } from '../_services/database.service';


import * as Highcharts from 'highcharts';
// import highcharts3D from 'highcharts/highcharts-3d.src';
// import drilldown from 'highcharts/modules/drilldown.src.js';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let drilldown = require('highcharts/modules/drilldown.src.js');
// highcharts3D(Highcharts);
// drilldown(Highcharts)
// Boost(Highcharts);
// noData(Highcharts);
// More(Highcharts);
// noData(Highcharts);

@Component({
  selector: 'app-dashboard-zonal-manager',
  templateUrl: './dashboard-zonal-manager.component.html',
  styleUrls: ['./dashboard-zonal-manager.component.scss']
})
export class DashboardZonalManagerComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  apiLoaded: Observable<boolean>;
  graphFilters:any={}
  constructor(
    // private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
  ) { 
    this.graphFilters.drNetworkPieChart = {};
    this.graphFilters.drNetworkPieChart.showFilter = false;
    this.graphFilters.leadSource = {};
    this.graphFilters.leadSource.showFilter = false;
    this.graphFilters.leadStateWiseBarGraph = {};
    this.graphFilters.leadStateWiseBarGraph.showFilter = false;
    this.graphFilters.drStateWiseBarGraph = {};
    this.graphFilters.drStateWiseBarGraph.showFilter = false;
    this.graphFilters.saleUserWorkingState = {};
    this.graphFilters.saleUserWorkingState.showFilter = false;
    
    
    this.graphFilters.salesStateWiseBarGraph = {};
    this.graphFilters.salesStateWiseBarGraph.showFilter = false;
    this.graphFilters.complaintStatusStateWiseBarGraph = {};
    this.graphFilters.complaintStatusStateWiseBarGraph.showFilter = false;
    this.graphFilters.salesProductWisePieChart = {};
    this.graphFilters.salesProductWisePieChart.showFilter = false;
    
    this.graphFilters.showLoading = true;
    
    
    this.apiLoaded = this.db.http.jsonp('https://maps.googleapis.com/maps/api/js?key=', 'callback')
    .pipe(
      map(() => true),
      catchError(() => of(false)),
    );
    console.log(this.apiLoaded,'apiLoaded');
    
  }
  
  ngOnInit() {
    
    this.graphData.drNetworkPieChart = {};
    this.graphData.leadSource = {};
    this.graphData.leadStateWiseBarGraph = {};
    this.graphData.salesStateWiseBarGraph = {};
    this.graphData.drStateWiseBarGraph = {};
    this.graphData.saleUserWorkingState = {};
    
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.getData();;
    // this.sales_working_map();
    // this.graph_lead_source();
    // this.graph_lead_state();
    // this.sales_working_state();
    // this.graph_dr_state();
    // this.get_user_lat_lng();
    
    this.pie_dr_type();
    this.graph_sales_state();
    this.graph_complaint_status_state();
    this.graph_product_wise_sales();
    // this.pie_complaint_status_state();
    
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  
  
  graphData:any={};
  getData(){
    
    this.dataNotFound = false;
    this.reqSent = false;
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    if(this.filter.date_from) this.filter.date_from =  moment(new Date (this.filter.date_from)).format('YYYY-MM-DD')
      if(this.filter.date_to) this.filter.date_to =  moment(new Date (this.filter.date_to)).format('YYYY-MM-DD')
        this.db.presentLoader();
    this.db.postReq(reqData,'dashboard/sfa').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'];
        let leadCountData=[];
        resp['result'].leadCountData.count.map((r,i)=>{
          leadCountData.push({y:r,name:resp['result'].leadCountData.statusList[i]});
        })
        this.graphData.leadCountData = {
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: "Lead network by Status"
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,  
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: [{
            name: 'Sales',
            data: leadCountData
          }],
          
        }
        Highcharts.chart('leadCountData-container', this.graphData.leadCountData);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  sales_working_map(){
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    this.db.postReq(reqData,'dashboard/sfa/sales_working_map').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        // this.data = resp['result'];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  graph_lead_source(){
    if(this.graphFilters.leadSource.date_from) this.graphFilters.leadSource.date_from =  moment(new Date (this.graphFilters.leadSource.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.leadSource.date_to) this.graphFilters.leadSource.date_to =  moment(new Date (this.graphFilters.leadSource.date_to)).format('YYYY-MM-DD')
        let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.leadSource
    }
    this.db.postReq(reqData,'/dashboard/sfa/graph_lead_source').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.graphData.leadSource = {
          chart: {
            type: "column",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 3,
              depth: 51,
              viewDistance: 25
            }
          },
          title: {
            text: "Lead Network Sources"
          },
          xAxis: {
            categories: resp['result'].leadSourceBarGraph.sourceList
          },
          yAxis: {
            title: {
              text: "Number of Leads"
            }
          },
          series: [
            {
              name: "Lead Network Sources",
              data: resp['result'].leadSourceBarGraph.count,
              colorByPoint: true
            }
          ]
        }
        Highcharts.chart('leadSource-container', this.graphData.leadSource);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
  graph_lead_state(){
    if(this.graphFilters.leadStateWiseBarGraph.date_from) this.graphFilters.leadStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.leadStateWiseBarGraph.date_to) this.graphFilters.leadStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.leadStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.leadStateWiseBarGraph
    }
    this.db.postReq(reqData,'dashboard/sfa/graph_lead_state').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.graphData.leadStateWise = {
          chart: {
            type: "column",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 3,
              depth: 51,
              viewDistance: 25
            }
          },
          title: {
            text: "Lead Network State wise"
          },
          xAxis: {
            categories: resp['result'].leadStateWiseBarGraph.stateList
          },
          yAxis: {
            title: {
              text: "Number of Leads"
            }
          },
          series: [
            {
              name: "Lead Network State wise",
              data: resp['result'].leadStateWiseBarGraph.count,
              colorByPoint: true
            }
          ]
        }
        Highcharts.chart('leadStateWise-container', this.graphData.leadStateWise);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  
  graph_dr_state(){
    if(this.graphFilters.drStateWiseBarGraph.date_from) this.graphFilters.drStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.drStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.drStateWiseBarGraph.date_to) this.graphFilters.drStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.drStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.drStateWiseBarGraph
    }
    this.db.postReq(reqData,'dashboard/sfa/graph_dr_state').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        
        resp['result'].drStateWiseBarGraph.map(r=>{
          r.y = r.count
          r.name = r.state_name
        })
        this.graphData.drStateWise = {
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: "Distribution network by state"
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,  
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: [{
            name: 'Sales',
            data: resp['result'].drStateWiseBarGraph
          }],
          
        }
        Highcharts.chart('drStateWise-container', this.graphData.drStateWise);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
  
  pie_dr_type(){
    if(this.graphFilters.drNetworkPieChart.date_from) this.graphFilters.drNetworkPieChart.date_from =  moment(new Date (this.graphFilters.drNetworkPieChart.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.drNetworkPieChart.date_to) this.graphFilters.drNetworkPieChart.date_to =  moment(new Date (this.graphFilters.drNetworkPieChart.date_to)).format('YYYY-MM-DD')
        let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.drNetworkPieChart
    }
    this.db.postReq(reqData,'dashboard/sfa/pie_dr_type').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        resp['result'].drNetworkPieChart.map(r=>{
          r.y = r.count
          r.name = r.cust_type
        })
        this.graphData.drNetwork = {
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: "Distribution network by Type"
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,  
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: [{
            name: 'Count',
            data: resp['result'].drNetworkPieChart
          }],
          
        }
        Highcharts.chart('drNetwork-container', this.graphData.drNetwork);
        
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  mapData:any=[];
  get_user_lat_lng(){
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.drStateWiseBarGraph
    }
    this.db.postReq(reqData,'dashboard/sfa/sales_working_map').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.mapData = resp['result'].salesUserMapLocation;
        this.mapData.map(r=>{
          if(r.last_lat)r.last_lat = Number(r.last_lat);
          if(r.last_lng)r.last_lng = Number(r.last_lng);
          r.lable = r.name+'-('+r.emp_code+') '+(r.last_address  ? r.last_address : '');
        })
        console.log(this.mapData);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
  backClicked() {
    // this._location.back();
  }
  goToUserDetail(data){
    console.log(data);
    this.db.router.navigateByUrl('sales-user-detail/'+data.user_encrypt_id)
  }
  goToDrDetail(data){
    if(data.is_lead == '1')this.db.router.navigateByUrl('dr-detail/'+data.customer_encrypt_id)
      else this.db.router.navigateByUrl('dealer-basic/'+data.customer_encrypt_id)
  }
  previous:any;
  clickedMarker(infowindow) {
    console.log('clickedMarker');
    
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  
  
  
  
  
  //  zonal dashboard start
  
  graph_sales_state(){
    if(this.graphFilters.salesStateWiseBarGraph.date_from) this.graphFilters.salesStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.salesStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.salesStateWiseBarGraph.date_to) this.graphFilters.salesStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.salesStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.salesStateWiseBarGraph
    }
    
    this.db.postReq(reqData,'dashboard/sfa/dr_sales_state').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        
        console.log('salesStateWiseBarGraph',resp['result'].salesStateWiseBarGraph);
        
        this.graphData.salesStateWise = {
          chart: {
            type: "column",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 3,
              depth: 51,
              viewDistance: 25
            }
          },
          title: {
            text: "Sales State Wise - CSP"
          },
          xAxis: {
            categories: resp['result'].salesStateWiseBarGraph.stateList
          },
          yAxis: {
            title: {
              text: "Number of Orders"
            }
          },
          series: [
            {
              name: "Sales State Wise - CSP",
              data: resp['result'].salesStateWiseBarGraph.count,
              colorByPoint: true
            }
          ]
        }
        
        console.log('this.graphData.salesStateWise',this.graphData.salesStateWise);
        Highcharts.chart('salesStateWise-container', this.graphData.salesStateWise);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  graph_complaint_status_state(){
    if(this.graphFilters.complaintStatusStateWiseBarGraph.date_from) this.graphFilters.complaintStatusStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.complaintStatusStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.complaintStatusStateWiseBarGraph.date_to) this.graphFilters.complaintStatusStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.complaintStatusStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.complaintStatusStateWiseBarGraph
    }
    
    this.db.postReq(reqData,'dashboard/sfa/complaint_status_state').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        
        console.log('complaintStatusStateWiseBarGraph',resp['result'].complaintStatusStateWiseBarGraph);
        this.graphFilters.showLoading = false;
        
        this.graphData.complaintStatusStateWise = {
          chart: {
            type: "column",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 3,
              depth: 51,
              viewDistance: 25
            }
          },
          title: {
            text: "Complaint Status State Wise"
          },
          xAxis: {
            categories: resp['result'].complaintStatusStateWiseBarGraph.stateList
          },
          yAxis: {
            title: {
              text: "Number of Orders"
            }
          },
          series: resp['result'].complaintStatusStateWiseBarGraph.count,
        }
        
        console.log('this.graphData.complaintStatusStateWise',this.graphData.complaintStatusStateWise);
        Highcharts.chart('complaintStatusStateWise-container', this.graphData.complaintStatusStateWise);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  pie_complaint_status_state(){
    if(this.graphFilters.complaintStatusStateWiseBarGraph.date_from) this.graphFilters.complaintStatusStateWiseBarGraph.date_from =  moment(new Date (this.graphFilters.complaintStatusStateWiseBarGraph.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.complaintStatusStateWiseBarGraph.date_to) this.graphFilters.complaintStatusStateWiseBarGraph.date_to =  moment(new Date (this.graphFilters.complaintStatusStateWiseBarGraph.date_to)).format('YYYY-MM-DD')
        
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.complaintStatusStateWiseBarGraph
    }
    
    this.db.postReq(reqData,'dashboard/sfa/complaint_status_state').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        
        console.log('complaintStatusStateWiseBarGraph',resp['result'].complaintStatusStateWiseBarGraph);
        this.graphFilters.showLoading = false;
        
        this.graphData.complaintStatusStateWise = { 
          chart: {
            type: 'pie'
          },
          title: {
            text: 'Browser market shares. January, 2022',
            align: 'left'
          },
          subtitle: {
            text: 'Click the slices to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>',
            align: 'left'
          },
          
          accessibility: {
            announceNewData: {
              enabled: true
            },
            point: {
              valueSuffix: '%'
            }
          },
          
          plotOptions: {
            series: {
              borderRadius: 5,
              dataLabels: [{
                enabled: true,
                distance: 15,
                format: '{point.name}'
              }, {
                enabled: true,
                distance: '-30%',
                filter: {
                  property: 'percentage',
                  operator: '>',
                  value: 5
                },
                format: '{point.y:.1f}%',
                style: {
                  fontSize: '0.9em',
                  textOutline: 'none'
                }
              }]
            }
          },
          
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
            '<b>{point.y:.2f}%</b> of total<br/>'
          },
          
          series: [
            {
              name: 'Browsers',
              colorByPoint: true,
              data: [
                {
                  name: 'Chrome',
                  y: 61.04,
                  drilldown: 'Chrome'
                },
                {
                  name: 'Safari',
                  y: 9.47,
                  drilldown: 'Safari'
                },
                {
                  name: 'Edge',
                  y: 9.32,
                  drilldown: 'Edge'
                },
                {
                  name: 'Firefox',
                  y: 8.15,
                  drilldown: 'Firefox'
                },
                {
                  name: 'Other',
                  y: 11.02,
                  drilldown: null
                }
              ]
            }
          ],
          drilldown: {
            series: [
              {
                name: 'Chrome',
                id: 'Chrome',
                data: [
                  [
                    'v97.0',
                    36.89
                  ],
                  [
                    'v96.0',
                    18.16
                  ],
                  [
                    'v95.0',
                    0.54
                  ],
                  [
                    'v94.0',
                    0.7
                  ],
                  [
                    'v93.0',
                    0.8
                  ],
                  [
                    'v92.0',
                    0.41
                  ],
                  [
                    'v91.0',
                    0.31
                  ],
                  [
                    'v90.0',
                    0.13
                  ],
                  [
                    'v89.0',
                    0.14
                  ],
                  [
                    'v88.0',
                    0.1
                  ],
                  [
                    'v87.0',
                    0.35
                  ],
                  [
                    'v86.0',
                    0.17
                  ],
                  [
                    'v85.0',
                    0.18
                  ],
                  [
                    'v84.0',
                    0.17
                  ],
                  [
                    'v83.0',
                    0.21
                  ],
                  [
                    'v81.0',
                    0.1
                  ],
                  [
                    'v80.0',
                    0.16
                  ],
                  [
                    'v79.0',
                    0.43
                  ],
                  [
                    'v78.0',
                    0.11
                  ],
                  [
                    'v76.0',
                    0.16
                  ],
                  [
                    'v75.0',
                    0.15
                  ],
                  [
                    'v72.0',
                    0.14
                  ],
                  [
                    'v70.0',
                    0.11
                  ],
                  [
                    'v69.0',
                    0.13
                  ],
                  [
                    'v56.0',
                    0.12
                  ],
                  [
                    'v49.0',
                    0.17
                  ]
                ]
              },
              {
                name: 'Safari',
                id: 'Safari',
                data: [
                  [
                    'v15.3',
                    0.1
                  ],
                  [
                    'v15.2',
                    2.01
                  ],
                  [
                    'v15.1',
                    2.29
                  ],
                  [
                    'v15.0',
                    0.49
                  ],
                  [
                    'v14.1',
                    2.48
                  ],
                  [
                    'v14.0',
                    0.64
                  ],
                  [
                    'v13.1',
                    1.17
                  ],
                  [
                    'v13.0',
                    0.13
                  ],
                  [
                    'v12.1',
                    0.16
                  ]
                ]
              },
              {
                name: 'Edge',
                id: 'Edge',
                data: [
                  [
                    'v97',
                    6.62
                  ],
                  [
                    'v96',
                    2.55
                  ],
                  [
                    'v95',
                    0.15
                  ]
                ]
              },
              {
                name: 'Firefox',
                id: 'Firefox',
                data: [
                  [
                    'v96.0',
                    4.17
                  ],
                  [
                    'v95.0',
                    3.33
                  ],
                  [
                    'v94.0',
                    0.11
                  ],
                  [
                    'v91.0',
                    0.23
                  ],
                  [
                    'v78.0',
                    0.16
                  ],
                  [
                    'v52.0',
                    0.15
                  ]
                ]
              }
            ]
          }
        }
        
        console.log('this.graphData.complaintStatusStateWise',this.graphData.complaintStatusStateWise);
        Highcharts.chart('complaintStatusStateWisePieChart-container', this.graphData.complaintStatusStateWise);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  graph_product_wise_sales(){
    if(this.graphFilters.salesProductWisePieChart.date_from) this.graphFilters.salesProductWisePieChart.date_from =  moment(new Date (this.graphFilters.salesProductWisePieChart.date_from)).format('YYYY-MM-DD')
      if(this.graphFilters.salesProductWisePieChart.date_to) this.graphFilters.salesProductWisePieChart.date_to =  moment(new Date (this.graphFilters.salesProductWisePieChart.date_to)).format('YYYY-MM-DD')
        
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.graphFilters.salesProductWisePieChart
    }
    
    this.db.postReq(reqData,'dashboard/sfa/product_sales_data').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        
        console.log('salesProductWiseBarGraph',resp['result'].salesProductWiseBarGraph);
        this.graphFilters.showLoading = false;
        
        resp['result'].salesProductWiseBarGraph.map(r=>{
          r.y = r.count
          r.name = r.product_name
        })
        
        this.graphData.salesProductWisePieChart = {
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          title: {
            text: "Product Wise Sales"
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,  
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: [{
            name: 'Sales',
            data:  resp['result'].salesProductWiseBarGraph
          }],
          
        }
        
        console.log('this.graphData.salesProductWise',this.graphData.salesProductWisePieChart);
        Highcharts.chart('productWiseSales-container', this.graphData.salesProductWisePieChart);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  
  // zonal dashboard end
  
}
