import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert';


@Component({
  selector: 'app-travel-add',
  templateUrl: './travel-add.component.html'
})
export class TravelAddComponent implements OnInit {
  showLoading:any=false;
  
  data:any=[];
  autoFocus: boolean = true;
  
  constructor(public db:DatabaseService,private _location: Location,public activatedroute:ActivatedRoute) { }
  form:any={};
  ngOnInit() {
    this.data = new Array(8);
    this.getsalesData();
    this.todayDate = moment().format('YYYY-MM-DD')
    // this.gettypes();
    this.getWorkingWith();
    this.generateMonthYearArray();
    if(this.activatedroute.snapshot.params.id){
      this.getDetails(this.activatedroute.snapshot.params.id)
    }
  }
  travelPlanDetail:any;
  travelPlanBasicDetail:any;
  getDetails(id){
    this.db.presentLoader();
    this.showLoading = true;
    
    this.form.sales_user = [];
    let reqData =  {
      "travel_plan_id" : id,
    }
    this.db.postReq(reqData,"travel_plan/detail").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      var teamMemberData = [];
      if(resp['status']=='success'){
        this.travelPlanBasicDetail = resp['data'].basic;
        this.travelPlanDetail = resp['data'].detail;
        this.monthDataArray = resp['data'].detail;
        // console.log('this.monthDataArray',this.monthDataArray)
        await this.monthDataArray.map(async r=>{
          teamMemberData = []
          r.date = r.travel_from
          r.day = r.travel_day
          r.district_city = r.city;
          this.form.plan_type = r.travel_type
          this.getDistrictData(r);

          await resp['data'].teamData.map(async x=>{
            if (r.id == x.travel_plan_id) {
              this.teamMember.map(y=> {
                if (x.user_id == y.user_id) {
                  teamMemberData.push(y) 
                }
                r.team_member = teamMemberData
              })
              
            }
          });
          // r.team_member = teamMemberData
          console.log('r.team_member',r.team_member);
          
        });
        
        const dateObject = resp['data'].basic
        
        const month = Math.max(1, Math.min(12, dateObject.month));
        const formattedDate = new Date(dateObject.year, month - 1).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
        this.form.month = formattedDate        
        
        this.form.sales_user = this.salesData.filter(row=>row.user_id == this.travelPlanBasicDetail.user_id)[0];
        this.getStates(this.form.sales_user);
        
        this.showLoading = false;
      }else{
        this.showLoading = false;
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      this.db.presentAlert('Error',err);
    })  
  }

  teamMember:any = []
  getWorkingWith() {
    let reqData = {
      limit : 100,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"get_all/assignedUserList").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.teamMember = resp['data'];
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }
  
  districts = [];
  getDistrict(row) {    
    let reqData =  {
      filter : row
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,"master/district_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.districts = resp['result'].data;       
        return;
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
    })  
  }
  
  // cityList = []
  // getCity(row) {
  //   let reqData =  {
  //     filter : {
  //       district_name : row
  //     }
  //   }
    
  //   this.db.presentLoader();
  //   this.db.postReq(reqData,"master/city_list").subscribe(async (resp:any) => {
  //     await this.db.dismissLoader();
  //     if(resp['message']=='Invalid token'){
  //       this.db.sessionExpire();
  //       return;
  //     }
  //     if(resp['status']=='success'){
  //       this.cityList = resp['result'].data;       
  //       return;
  //     }else{
  //       this.db.presentAlert(resp['status'],resp['message']);
  //     }
  //   },async err => {
  //   })  
  // }
  
  
  districtData = [];
  districtCityName:any = '';
  filter:any = '';
  getDistrictData(row) {
    // console.log('row-->',row);
    
    let reqData =  {
      filter : {
        state_name : row.state_name
      }
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,"master/city_list").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return 'invalid';
      }
      if(resp['status']=='success'){
        row.districtData = resp['result'].data;
        row.districtData.map(r=>{
          r.districtCityName = r.city;
        })
        // console.log('--->',row.districtData);
        this.districtData = row.districtData;
        return;
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
    })  
  }
  
  
  states:any=[]
  getStates(sales_user){
    // console.log('user-->',sales_user)
    let reqData =  {
      filter : this.filter,
      user_id : sales_user.user_id
    }
    this.db.postReq(reqData,'master/assigned_user/state_list').subscribe(async resp=>{
      this.states= resp['result'].data
      
    })
  }
  
  setSameState(row,index){
    console.log('this.activatedroute.snapshot.params.id',this.activatedroute.snapshot.params.id);
    if (this.activatedroute.snapshot.params.id) {
      return
    } else {
      if(row.state_name){
        this.monthDataArray.map((x, i)=>{
          // console.log('x',x, 'i', i);
          if(i > index){
            
            this.showLoading = true;
            setTimeout(() => {
              // console.log('this.districtData -->',this.districtData);      
              x.districtData = this.districtData;
              this.showLoading = false;  
              
            }, 1000 * 5);
            
            x.state_name = row.state_name;
          }
        });
      }
      // console.log('monthDataArray after -->', this.monthDataArray);
    }

  }
  
  todayDate:any
  salesData:any=[];
  filteredSalesData:any=[];
  getsalesData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
      }
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,"get_junior/assignedUserList").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.salesData = resp['data'];
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
      console.log('err',err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  onSubmit(){
    
    var travelPlanMonthData = [];
    
    this.form.monthTravelPlan = travelPlanMonthData;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      console.log(this.form.invalid);
      return;
    }
    
    if(!this.monthDataArray.length){
      this.db.presentAlert('Required','Please fill at least 1 day travel plan');
      return;
    }
    
    if (this.activatedroute.snapshot.params.id) {
      this.form.travel_plan_id = this.activatedroute.snapshot.params.id
      this.monthDataArray.map(r=>{
        if (typeof(r.district_city) ==  'object') {
          r.district_name = r.district_city.district_name;
          r.city = r.district_city.city; 
          r.area = r.district_city.area;
          r.district_city = r.district_city.districtCityName;
        }
        if (typeof(r.state_name) ==  'object') {
          r.state_name = r.state_name ? r.state_name.state_name : '';
          // r.state_name = r.state_name.state_name;
        } 
        
        var tempData = {
          date : r.date,
          description : r.description,
          state_name : r.state_name,
          district_city : r.district_city,
          district_name : r.district_name,
          holiday : r.holiday,
          city : r.city,
          area : r.area,
          user : this.form.sales_user,
          travel_plan_id : r.id,
          status : this.form.status,
          working_with : r.team_member,
        }
        travelPlanMonthData.push(tempData);
      });
      
      
    } else {
      this.monthDataArray.map(r=>{
        var tempData = {
          date : r.date,
          description : r.description,
          state_name : r.state_name,
          district_city : r.district_city,
          holiday : r.holiday,
          area : r.area,
          user : r.user,
          travel_plan_id : r.id,
          working_with : r.team_member,
          status : this.form.status
        }
        travelPlanMonthData.push(tempData);
      });
    }
    let reqData = this.form
    console.log('reqData --->', reqData);
    
    // return
    
    reqData.travel_to  = moment(this.form.travel_to).format('YYYY-MM-DD'),
    reqData.description  = this.form.description
    
    this.db.presentLoader();
    this.db.postReq(reqData,this.activatedroute.snapshot.params.id ? 'travel_plan/update' :'travel_plan/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
  holiday:any = ''
  monthDataArray:any=[];
  month:any = '';
  year :any = '';
  // monthYearArray = [];
  async getDaysArray(year,month) {
    
    const firstDayOfMonth = moment({ year:year, month:month - 1, day: 1 });
    const daysInMonth = firstDayOfMonth.daysInMonth();
    
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = moment({ year:this.year, month: this.month - 1, day });
      console.log('currentDate',currentDate.format('YYYY-MM-DD'));
      
      console.log('holidayData',this.holidayData);
      
      this.monthDataArray.push({
        date : currentDate.format('YYYY-MM-DD'),
        day : currentDate.format('dddd'),
        user : this.form.sales_user,
        holiday : '',
        state : '',
        district : '',
        customer : '',
        description  : ''
      });
      
      this.monthDataArray.map(x=>{
        const tempData = this.holidayData.filter((y:any)=> moment(y.holiday_date).format('YYYY-MM-DD')  == x.date);
        if(tempData.length){
          x.holiday = tempData[0].holiday_title;
        }
        console.log('x.holiday',tempData);
      });
      
    }
    
    console.log('monthDataArray -->', this.monthDataArray);
  }
  
  result:any = [];
  generateMonthYearArray() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); 
    const currentYear = currentDate.getFullYear();
    this.result = [];
    
    for (let i = -2; i < 4; i++) {
      let monthIndex = (currentMonth + i) % 12;
      let year = currentYear + Math.floor((currentMonth + i) / 12);
      if (monthIndex < 0) {
        monthIndex += 12;
        // year--;
      }
      const monthYearString = `${months[monthIndex]} ${year}`;
      this.result.push(monthYearString);
    }
    // console.log('result',this.result);
    
  }
  
  
  deleteMonthWiseData(row,i) {
    // console.log(row);
    row.district_city = {};
    row.state_name = '';
    row.description = '';
    row.team_member = [];
  }
  
  checkPlanData:any = [];
  holidayData:any=[];
  showButton:any = false; 
  encriptId:any = '';
  checkPlanCreated() {
    var monthMap = {
      'Jan': 1,
      'Feb': 2,
      'Mar': 3,
      'Apr': 4,
      'May': 5,
      'Jun': 6,
      'Jul': 7,
      'Aug': 8,
      'Sep': 9,
      'Oct': 10,
      'Nov': 11,
      'Dec': 12
    };
    this.form.arrayOfDates = []
    this.monthDataArray = [];
    var dateComponents = this.form.month.split(' ');
    this.month = monthMap[dateComponents[0]];
    this.year = parseInt(dateComponents[1]);
    // console.log(this.form.sales_user);
    let reqData =  {
      user_id : this.form.sales_user.user_id,
      year : this.year,
      month : this.month,
      states : this.states
    }
    
    this.db.presentLoader();
    this.db.postReq(reqData,"travel_plan/check_plan").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['result'].length){     
        this.checkPlanData = resp['result'];
        this.encriptId = this.checkPlanData[0].travel_plan_encrypt_id
        this.db.presentAlert('Plan Already Created','You can view your plan here');
        this.showButton = true;
      }
      else{
        this.holidayData = resp['holiday'];
        this.getDaysArray(this.year,this.month);
        this.showButton = false;
      }
    },async err => {
    })  
  }

}
