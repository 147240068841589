import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ComplaintStatusComponent } from '../complaint-status/complaint-status.component';

@Component({
  selector: 'app-complaint-module-list',
  templateUrl: './complaint-module-list.component.html',
})
export class ComplaintModuleListComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  checkModulePermission:any={};

  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public activatedroute:ActivatedRoute,
    public event:CustomEventsService

    ) { 
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);
      this.getComplaintStatus();

      this.event.subscribe('refresh-complaint-module-list', (data: any) => {
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        console.log('refresh');

      })
    }

    blankTrData :any
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'complaint_module').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.complaint_id = data.id;
          this.db.postReq(data,'delete_complaint_module').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.getData();
              swal("Deleted", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }

    detailData:any={};
  getDetail(data:any){
    this.db.presentLoader();
    
    this.db.postReq({'complaint_id':data.id},'complaint_module_detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.detailData = resp['data'].detail;   

        this.detailData = Object.assign({}, resp['data'].detail);
        // this.detailData = Object.assign({}, this.detailData);
        this.detailData.product_name = data.product_name;
        this.detailData.company_name = data.company_name;
        this.detailData.cust_type = data.cust_type;
        this.detailData.street = data.street;
        this.detailData.state_name = data.state_name;
        this.detailData.district_name = data.district_name;
        console.log(this.detailData);
        
        setTimeout(() => {
          this.print();
        }, 1000);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }

  print(){
    let prtContent = document.getElementById("invoice-detail");
    console.log('prtContent',prtContent);
    let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    console.log('WinPrint',WinPrint);
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  }

  statusData:any=[];
  tabActiveType:any={};
  tabActive(status:any)
  {
    this.filter.status = status;
    this.start=0;
    this.limit=50;
    this.getData()
  }
  getComplaintStatus(){
    console.log('this.activatedroute.snapshot.params.type',this.activatedroute.snapshot.params.type);
    
    this.db.presentLoader();
    this.db.postReq({getAssignedUserData : this.db.getAssignedUserData},'complaint/get_complaint_status').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.statusData = resp['result'].data;
        this.tabActive( resp['result'].data[0].name);
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  openStatusDialog(data:any): void {
    console.log('openStatusDialog',data);
    const dialogRef = this.dialog.open(ComplaintStatusComponent, {
      width: '400px',
      autoFocus: false,
      data: data
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getData();     
    });
  }
}
