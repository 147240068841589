import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';
import { ImportResultComponent } from '../import-result/import-result.component';

@Component({
  selector: 'app-import-data-model',
  templateUrl: './import-data-model.component.html',
})
export class ImportDataModelComponent implements OnInit {

  constructor(public db:DatabaseService,@Inject(MAT_DIALOG_DATA) public data: any,private _location: Location,public dialog: MatDialog,) { }

  ngOnInit() {
    this.formsInit(this.data);
    
  }

  leadData:any
  file:any
  fileName:any;
  arrayBuffer:any;
  addfile(event)     
  {    
    this.file= event.target.files[0]; 
    this.fileName = this.file.name
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.file);     
    fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      console.log("arraylist",arraylist)
      var newarraylist = arraylist.map((item)=>({
        coupon_code:item["Coupon Code"],
        product_code:item["Product Code"],
        coupon_value:item["Coupon Value"]
        
      }))
      // console.log("newarraylist",newarraylist)
      this.leadData = arraylist
      console.log("this.leadData",this.leadData)
       
    }    
  }

  form: FormGroup;
  formsInit(data:any) {
    console.log(this.data);
    
    this.form = this.db.formBuilder.group({
      // valid_upto: [data.valid_upto ? data.valid_upto : null ,[Validators.required]],
      // offer_id:[data.valid_upto ? data.valid_upto : null ,[]],
      
    })
    console.log(this.form.value);

  }
 
  onSubmit(){
    if(this.form.invalid || this.leadData.length == 0){
      return
    }
    this.db.postReq({importData:this.leadData,cust_type: this.data},'dis_network/import_network').subscribe(resp=>{
      if(resp['status'] == 'success'){
        if (resp['data'].customerNotAdded.length) {
          const dialogRef = this.dialog.open(ImportResultComponent, {
        
            width: '6000px',
            // height : '6000px',
            autoFocus: false,
            data : {
              customerNotAdded : resp['data'].customerNotAdded,
              customerAdded : resp['data'].customerAdded
            }
          });
        } else {
          swal('Success', {
            title: "Data import succesfully",
            text: resp['data'].customerAdded.length+' Customer added',
            icon: "success",
        });
        this.db.matdialog.closeAll();
        }
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })  
   
    
  }
  backClicked() {
    this._location.back();
  }
}

