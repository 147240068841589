import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GiftStatusModelComponent } from 'src/app/redeem-req/gift-status-model/status-model.component';
import { RedeemRequestStatusModelComponent } from 'src/app/redeem-req/redeem-status-model/status-model.component';

@Component({
  selector: 'app-dealer-redeem-list',
  templateUrl: './dealer-redeem-list.component.html'
})
export class DealerRedeemListComponent implements OnInit {
 
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    public activatedroute:ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { 
      this.filter.gift_status='';
      this.filter.redeem_status = '';
    }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        customer_encrypt_id: this.activatedroute.snapshot.params.id,
        registered: 0,
 
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'redeem_request/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.role_id = data.id;
          this.db.postReq(data,'master/update_role').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
    // openDetail(row): void {
    //   console.log(row);
    
    //   const dialogRef = this.dialog.open(RoleDetailModelComponent, {
    //     width: '768px',
    //     autoFocus: false,
    //     data: row
    //   });
    
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closed');
    //   });
    // }
    openStatusDialog(data): void {
      console.log(data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(RedeemRequestStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        // this.getCounters();     
        this.getData();
      });
    }
    openGiftDialog(data): void {
      console.log(data);
      // this.db.redeemRequestData = data;
      const dialogRef = this.dialog.open(GiftStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        // this.getCounters();     
        this.getData();
      });
    }
    downloadExcel(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        filter : this.filter,
        customer_encrypt_id: this.activatedroute.snapshot.params.id,
        registered: 0,
 
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'redeem_request/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
  }
  