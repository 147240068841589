import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PaymentStatusModelComponent } from '../payment/payment-status-model/payment-status-model.component';
import { RemarkModelComponent } from '../remark-model/remark-model.component';
import swal from 'sweetalert';
import { DatabaseService } from '../_services/database.service';
import * as moment from 'moment';
import { RedeemRequestStatusModelComponent } from '../redeem-req/redeem-status-model/status-model.component';
import { LeaveStatusModelComponent } from '../leave-status/status-model.component';
import { LeaveListDetailModelComponent } from './leave-list-detail-model/leave-list-detail-model.component';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-leave-list',
  templateUrl: './leave-list.component.html'
})
export class LeaveListComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  checkModulePermission:any = {}
  showLoading:any=false;
  
  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService,
    public activatedroute:ActivatedRoute
    ) {  
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);

      this.event.subscribe('refresh-leave-list', (data: any) => {
        // this.type =this.activatedroute.snapshot.params.type
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        console.log('refresh');
        
      })
    }
    ngOnInit() {
      this.filter.status = this.tabActive;
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.userRole = JSON.parse(localStorage.getItem('hmdUserData')) 
    }
    userRole:any ='';
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      // this.filter.search = null
      this.filter.status =  this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'leave/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          console.log(this.data)
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      this.db.postReq({getAssignedUserData : this.db.getAssignedUserData},'leave/list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.counters= resp['result'].data
          console.log(this.counters);
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: "You want to delete this",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          // data.status = data.status == 0 ? 1 : 0;
          data.leave_id = data.id;
          this.db.postReq(data,'leave/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Deleted", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    sendMail(data){
      // console.log('data',data);
      // return     
      swal({
        title: "Are you sure?",
        text: "You want to Send Email",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.leave_id = data.id;
          this.db.postReq(data,'leave/send_mail').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Mail Sent", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        // this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    openStatusDialog(data:any): void {
      console.log('openStatusDialog',data);
      const dialogRef = this.dialog.open(LeaveStatusModelComponent, {
        width: '400px',
        autoFocus: false,
        data: data
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getCounters();     
      });
    }
   
  
    downloadExcel(){
      this.filter.gift_status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'leave/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

    openDetail(row): void {
      console.log(row);
      
      const dialogRef = this.dialog.open(LeaveListDetailModelComponent, {
        width: '768px',
        autoFocus: false,
        data: row
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    selectAll(event){
      this.data.map(r=>{
        if(event.checked == true){
          r.checked = true;
        }else{
          r.checked = false
        }
      })
    }

    approvalArray:any = []
  sendForApproval(data) {
    // console.log('data',data);
    // return
    this.approvalArray = [];
    data.map(row=>{
      if(row.checked) this.approvalArray.push({id:row.id,user_id:row.user_id,user_name:row.user_name,user_email:row.email,designation:row.designation,user_mobile:row.mobile_no,start_date:row.start_date,end_date:row.end_date,description:row.description});
    })
    let reqData = {
      data : this.approvalArray
    }
    console.log(this.approvalArray,data);
    
    if(!this.approvalArray.length){
      this.db.presentAlert('Warning','Please select at least 1 Leave')
      return;
    }
    
    swal({
      title: "Are you sure?",
      text: "You Want To Send This For Approval",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    
    .then((willDelete) => {        
      if (willDelete) {
        this.showLoading = true;
        this.db.presentLoader();
        this.db.postReq(reqData,'leave_plan/send_for_approval').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal("Sent for Approval", {
              icon: "success",
            });
            // this.getDetail(this.activatedroute.snapshot.params.id);
            this.showLoading = false;
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.showLoading = false;
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Request Not Sent!");
      }
    });
    
  }
  }
  
  
  
  