import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { CheckInImageModelComponent } from './check-in-image-model/check-in-image-model.component';
import { DatabaseService } from '../_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-check-in-list',
  templateUrl: './check-in-list.component.html'
})
export class CheckInListComponent implements OnInit {

 
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  checkModulePermission:any={};
  showLoading:any=false;

  constructor(
    private _location: Location,
    public activatedroute:ActivatedRoute,
    public dialog: MatDialog,
    public db:DatabaseService,
    public event:CustomEventsService
    ) {
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);

      this.event.subscribe('refresh-checkin-list', (data: any) => {
        // this.type =this.activatedroute.snapshot.params
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        console.log('refresh');

      })
     }
    ngOnInit() {
      this.filter.status = ''
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'checkin/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          console.log(this.data);
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }  
   
    
    downloadExcel(){
      let reqData = {
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'checkin/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

    openDetail(row): void {
      console.log(row);
      
      const dialogRef = this.dialog.open(CheckInImageModelComponent, {
        width: '768px',
        autoFocus: false,
        data: row
      });
      
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    sendReport(){
      let reqData = {
        filter : this.filter
      }
      swal({
        title: "Are you sure?",
        text: "You want to share visit data over email !",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.showLoading = true;
          this.db.presentLoader();
          this.db.postReq(reqData,'checkin/weekly/list/excel').subscribe(resp=>{
            if(resp['status'] == 'success'){
              this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
              swal("Mail sent succesfully", {
                icon: "success",
              });
              this.getData();
              this.showLoading = false;
            }else{
              this.showLoading = false;
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }

    delete(data){
      console.log(data);
      // return
      swal({
        title: "Are you sure?",
        text: "You want to delete this !",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.checkin_id = data.id;
          this.db.postReq(data,'checkin/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal("Deleted", {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
  }
  