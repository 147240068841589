import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import  swal from 'sweetalert';
import * as moment from 'moment';

@Component({
  selector: 'app-live-track-detail',
  templateUrl: './live-track-detail.component.html',
  // styleUrls: ['./dr-detail.component.scss']
})
export class LiveTrackDetailComponent implements OnInit {
  
  mapCenterLat = 28.704060;
  mapCenterLng = 77.102493;
  mapZoom = 15;
  
  constructor(public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  
  origin:any;
  destination:any;
  waypoints:any=[];
  waypointsNew:any=[];
  data:any={};
  location:any;
  userData:any;
  icon_path='https://w7.pngwing.com/pngs/457/630/png-transparent-location-logo-location-computer-icons-symbol-location-miscellaneous-angle-heart.png';
  markerOptions = {
    origin: {
      icon: this.icon_path
    },
    destination: {
      icon: this.icon_path
    },
    waypoints: {
      icon: this.icon_path
    }
  }
  
  renderOptions = {
    suppressMarkers: true
  };
  
  getDetail(id:any){
    this.db.presentLoader();
    
    this.db.postReq({'user_id':id,created_on:moment(this.activatedroute.snapshot.params.date).format('YYYY-MM-DD')},'master/track_location').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.userData = resp['result'].detail;   
        this.data = resp['result'].data;   
        console.log(this.data);
        // this.getUsers();
        if(this.data.length){
          await this.data.map(r=>{
            r.lat = Number(r.lat);
            r.lng = Number(r.lng);
          })
        }
        this.origin = this.data[0];
        
        this.mapCenterLat = this.origin.lat
        this.mapCenterLng = this.origin.lng;
        
        this.destination = this.data[this.data.length-1];
        
        this.data.forEach((element, index) => {
          element.pointer_label = index + 1;

          element.icon='https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|4286f4';
          if(element.attendance_id){
            element.icon='https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|FF0000';
          }
          if(element.checkin_id){
            element.icon='https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|FF0000';
          }

          element.pointer_label = element.pointer_label.toString();
          // element.pointer_label = "1";
          // if (index !== 0 && index !== this.data.length - 1) {
          this.waypoints.push(
            {'location' : {lat : element.lat, 'lng' : element.lng}}
            )
            // }
          });
          console.log('waypoints', this.waypoints);
          
          console.log('this.data',this.data);
          

        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
      
    }
    
    
    updateAddress(){
      this.db.presentLoader();
      
      this.db.getReq({},'cron/update_live_track_address').subscribe(async resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          location.reload();
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
      
    }
    backClicked() {
      this._location.back();
    }
    previous:any;
    clickedMarker(infowindow) {
      console.log('clickedMarker');
      
      if (this.previous) {
        this.previous.close();
      }
      this.previous = infowindow;
    }  
  }
  