import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';

@Component({
  selector: 'app-leave-add',
  templateUrl: './leave-add.component.html',
})
export class LeaveAddComponent implements OnInit {

  showLoading:any=false;
  constructor(private _location: Location,public db:DatabaseService,public activatedroute:ActivatedRoute) { }
  
  
  

  ngOnInit() {
    // this.getData();
    this.getsalesData();
    if(this.activatedroute.snapshot.params.id){
      this.getDetail(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }

  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      sales_user : [data.user_id ? data.user_id : null , [Validators.required]],
      type: [data.type ? data.type : null , [Validators.required]],
      sub_type: [data.sub_type ? data.sub_type : null , [Validators.required]],
      start_date : [this.activatedroute.snapshot.params.id ? moment(data.start_date).format('YYYY-MM-DD'): moment(new Date ()).format('YYYY-MM-DD')  , []],
      end_date : [this.activatedroute.snapshot.params.id ? moment(data.end_date).format('YYYY-MM-DD'): moment(new Date ()).format('YYYY-MM-DD')  , []],
      subject : [this.activatedroute.snapshot.params.id ? data.subject : null ,[]],
      remark: [this.activatedroute.snapshot.params.id ? data.description : null ,[]],
      leave_id: [this.activatedroute.snapshot.params.id ? data.id : null],
    })
  }

  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'leave_id':id},'leave/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['result']['data']);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['name'].markAsTouched();
      this.form.controls['code'].markAsTouched();
      this.form.controls['description'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      this.form.controls['sales_user'].markAsTouched();
      return
    }
    this.form.value.total_days = moment(this.form.value.end_date).diff(moment(this.form.value.start_date), 'days') + 1;
    console.log(this.form.value);
    // return
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.activatedroute.snapshot.params.id ? 'leave/update' : 'leave/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }

  // data:any={}
  // filteredData:any={};
  // getData(){
    
  //   this.db.postReq({start:0,limit:1000,filter:{}},'master/product_category').subscribe(resp=>{
  //     if(resp['status'] == 'success'){
  //       this.data.categoryData = resp['result'].data        ;
  //       this.filteredData.categoryData = resp['result'].data        
  //     }else{
  //       if(resp['message']=='Invalid token'){
  //         this.db.sessionExpire();
  //         return;
  //       }
  //       this.db.presentAlert(resp['status'],resp['message'])
  //     }
  //   })
  // }

  salesData:any=[];
  filteredSalesData:any=[];
  getsalesData(){
    let reqData =  {
      "limit" :100000,
      "start" : 0,
      "filter" : {
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"travel_plan/sales_user").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['message']=='Invalid token'){
        this.db.sessionExpire();
        return;
      }
      if(resp['status']=='success'){
        this.filteredSalesData = resp['result'].data;
        if (JSON.parse(localStorage.getItem('hmdUserData')).role != '1') {
          this.filteredSalesData.map(r=>{
            if (JSON.parse(localStorage.getItem('hmdUserData')).id == r.id) {
              this.salesData.push(r)
            }
          })
          
          if (JSON.parse(localStorage.getItem('assignedUserData')).length) {
            this.filteredSalesData.map(r=>{
              let i = JSON.parse(localStorage.getItem('assignedUserData')).findIndex(row=>row.user_id == r.id)
              if(i!=-1)this.salesData.push(r)
            })
            // console.log('yes',JSON.parse(localStorage.getItem('assignedUserData')));
            
          } else {
            this.filteredSalesData.map(r=>{
              if (JSON.parse(localStorage.getItem('hmdUserData')).id == r.id) {
                // console.log('here i am', r);
                // this.form.sales_user = r
                // this.getStates(this.form.sales_user)
              }
            })
            // console.log('No');
          }
        } else {
          this.salesData = this.filteredSalesData
        }
        
      }else{
        this.db.presentAlert(resp['status'],resp['message']);
      }
    },async err => {
    })  
  }

}
