import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProductDetailModelComponent } from 'src/app/product/product-detail-model/product-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { BulkUserAssignModelComponent } from '../bulk-user-assign-model/bulk-user-assign-model.component';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ImportDataModelComponent } from '../import-data-model/import-data-model.component';


@Component({
  selector: 'app-dr-list',
  templateUrl: './dr-list.component.html',
  // styleUrls: ['./dr-list.component.scss']
})
export class DrListComponent  {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  tabActiveType:any={};
  tabActive:any='All'
  type:any 
  typeName:any;
  checkModulePermission:any={};
  constructor(
    public activatedroute:ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService,
    private router: Router
  ) {  
    this.event.subscribe('refresh-dr-list', (data: any) => {
      // this.type =this.activatedroute.snapshot.params.type
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
      console.log('refresh');
    });
  }
  
  ngAfterViewInit() {
       // this.checkModulePermission = this.db.loggedInPermission(2,this.activatedroute.snapshot.url[0].path+'/'+this.activatedroute.snapshot.params.type);
      console.log('checkModulePermission -->',this.checkModulePermission);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.url[0].path+'/'+this.activatedroute.snapshot.params.type);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.params.type);
      this.checkModulePermission = this.db.loggedInPermission(2,this.activatedroute.snapshot.url[0].path+'/'+this.activatedroute.snapshot.params.type);
  }

  ngOnInit() {
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.getData();
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  getData(){
    this.dataNotFound = false;
    this.reqSent = false;
    this.type = this.activatedroute.snapshot.params.type
    this.typeName =  this.type == 1 ? 'Distributor' : 'Customer'
    this.filter.cust_type_id = this.type
    this.filter.is_lead = "0";
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter,
      getAssignedUserData : this.db.getAssignedUserData
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/list').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        console.log(this.data);
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == (this.start+this.data.length)) return;
      
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start-this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    this.filter = {};
    this.getData();
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want to delete this "+this.typeName+"!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        data.status = data.status == 0 ? 1 : 0;
        data.customer_id = data.encrypt_id;
        this.db.postReq(data,'dis_network/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal(data.status == 0 ? "Inactive!" : "Active", {
              icon: "success",
            });
            this.getData();
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
  
  
  // selectedDr:any = []
  // openBulkUserAssignedDialog(data): void {
  //   this.data.map(row=>{
  //     // if(row.checked) this.selectedDr.push({id:row.id});
  //     if(row.checked == true){
  //       let index  = this.selectedDr.findIndex(r=> r.id == row.id);
  //       console.log(' index -->',index);
  //       if(index == -1 ){
  //         if(row.checked) this.selectedDr.push({id:row.id});
  //       }  
  //     }
  //   })
  //   console.log("data",data)
  //   // this.data.basicDetail.encrypt_id =  this.activatedroute.snapshot.params.id
  //   const dialogRef = this.dialog.open(BulkUserAssignModelComponent, {
  
  //     width: '768px',
  //     autoFocus: false,
  //   });
  
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.event.publish('refresh-lead-detail',{});
  //     this.getData();
  //   });
  // }
  
  selectedDr:any = []
  openBulkUserAssignedDialog(data): void {
    this.selectedDr = []
    this.data.map(row=>{
      if(row.checked) this.selectedDr.push({id:row.id});
    })
    console.log("data",data)
    // this.data.basicDetail.encrypt_id =  this.activatedroute.snapshot.params.id
    const dialogRef = this.dialog.open(BulkUserAssignModelComponent, {
      width: '400px',
      autoFocus: false,
      data: this.selectedDr
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.event.publish('refresh-lead-detail',{});
      this.getData();
    });
  }
  
  removeBulkUserAssignedDialog(data): void {
    this.data.map(row=>{
      if(row.checked) this.selectedDr.push({id:row.id});
    })
    console.log(this.selectedDr);
    let reqData = {
      dr_ids : this.selectedDr,
    }
    console.log("reqData",reqData)
    swal({
      title: "Are you sure?",
      text: "You want to remove all assigned sales executive ",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        // data.customer_id = data.encrypt_id;
        this.db.postReq(reqData,'dis_network/bulk_delete/assigned_user').subscribe(resp=>{
          if(resp['status'] == 'success'){
            this.getData();
            swal("Deleted", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
        this.getData();
      }
    });
  }
  
  selectAll(event){
    this.data.map(r=>{
      if(event.checked == true){
        r.checked = true;
      }else{
        r.checked = false
      }
    })
  }
  
  backClicked() {
    this._location.back();
  } 
  status:boolean = false
  
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, 'toggle-active-tlp');
    }
    else {
      // this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  
  openImportFilemodel() {
    
    console.log('cust_type',this.type);
    
    const dialogRef = this.dialog.open(ImportDataModelComponent, {
      
      width: '600px',
      autoFocus: false,
      data : this.type
    });
    
  }

  downloadExcel(){
    this.type = this.activatedroute.snapshot.params.type;
    this.filter.cust_type_id = this.type;
    this.filter.registered = 0;
    this.filter.status = this.tabActive == 'All' ? '' : this.tabActive;
    let reqData = {
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/list/excel').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
}
