import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-check-in-image-model',
  templateUrl: './check-in-image-model.component.html',
})
export class CheckInImageModelComponent implements OnInit {

  gallery:any=[];
  // @Inject(MAT_DIALOG_DATA) public data: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // setTimeout(() => {
      
    //   console.log(this.data);
    // }, 2000);
    
   }

   
  ngOnInit() {
    this.gallery = new Array(4);
  }

}
