import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-product-divison-detail-model',
  templateUrl: './product-divison-detail-model.component.html',
})
export class ProductDivisonDetailModelComponent implements OnInit {

  gallery:any=[];
  // @Inject(MAT_DIALOG_DATA) public data: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // setTimeout(() => {
      
    //   console.log(this.data);
    // }, 2000);
   }


  ngOnInit() {
    console.log("this.data",this.data)
    this.gallery = new Array(4);
  }

}
