import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatSelectModule } from '@angular/material/select';
import  swal from 'sweetalert';
import * as moment from 'moment';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'app-purchase-order-add',
  templateUrl: './purchase-order-add.component.html'
})
export class PurchaseOrderAddComponent implements OnInit {
  formSubmitted:any;
  data:any=[];
dataNotFound=false
reqSent=false
  constructor(private _location: Location,public db:DatabaseService) { 
    this.getVendors();
    this.getProducts();
    this.getPONo();
    this.form.orderItem=[];
    this.form.basicData={
      net_amt:null,
      total_qty:null,
      total_amt:null,
      tax_amt:null,
      discount_amt:null,
      orderItem:null,
      vendor_id:null,
      po_no:null,
      po_date:moment(new Date()).format("YYYY-MM-DD"),
      delivery_date:moment(new Date()).format("YYYY-MM-DD"),
    };
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null
    };
  }
  form:any={};
  ngOnInit() {
    this.data = new Array(7);
  }
  
  backClicked() {
    this._location.back();
  }
  filter:any={};
  vendorData:any=[]
  filteredVendorData:any=[]
  getVendors(){
    let reqData = {
      limit : 100,
      start : 0,
      filter : {company_name:this.filter.company_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'po/vendor_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.vendorData = resp['result'].data;
        this.filteredVendorData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  productsData:any=[]
  filteredProductsData:any=[]
  getProducts(){
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {vendor_product_name:this.filter.vendor_product_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/product_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        await resp['result'].data.map(r=>{
          console.log(r);
          
          r.searchName = r.vendor_product_name.replace(/ /g,'').toLowerCase();
        })
        this.productsData = resp['result'].data;
        this.filteredProductsData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  getPONo(){
    let reqData = {
      
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'po/get_po_no').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.form.basicData.po_no = resp['result'].data.po_no;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  calc(){
    console.log(this.form.product_detail);
    // alert('test')
    if(this.form.product_detail.product_id){
      let product_detail = this.productsData.filter(r=>r.id===this.form.product_detail.product_id)[0]
      console.log(this.form);
      
      this.form.product_detail.rate = product_detail.sale_price;
      this.form.product_detail.stock_qty = product_detail.stock_qty;
      this.form.product_detail.vendor_product_name = product_detail.vendor_product_name;
      this.form.product_detail.product_group = product_detail.product_group;
      this.form.product_detail.product_code = product_detail.product_code;
      this.form.product_detail.vendor_id = this.form.vendor_id;
      this.form.product_detail.dispatch_qty = 0;
      this.form.product_detail.pending_qty = this.form.product_detail.order_qty;
      this.form.product_detail.discount = product_detail.discount_percentage;  
      this.form.product_detail.tax = product_detail.tax_percentage;  
      
      this.form.product_detail.net_amt =  (this.form.product_detail.rate * this.form.product_detail.order_qty)
      this.form.product_detail.discount_amt = ((this.form.product_detail.net_amt / 100 ) * this.form.product_detail.discount)  
      this.form.product_detail.tax_amt =  (((this.form.product_detail.net_amt - this.form.product_detail.discount_amt) / 100) *  this.form.product_detail.tax)
      this.form.product_detail.total_amt = ((this.form.product_detail.net_amt - this.form.product_detail.discount_amt ) + this.form.product_detail.tax_amt)
      
      this.form.product_detail.net_amt =       this.form.product_detail.net_amt.toFixed(2)
      this.form.product_detail.discount_amt = this.form.product_detail.discount_amt.toFixed(2)
      this.form.product_detail.tax_amt = this.form.product_detail.tax_amt.toFixed(2)
      this.form.product_detail.total_amt = this.form.product_detail.total_amt.toFixed(2)
    }else{
      this.form.product_detail={
        stock_qty:null,
        order_qty:null,
        rate:null,
        discount:null,
        discount_amt:null,
        tax:null,
        tax_amt:null,
        total_amt:null
      };
    }
  }
  addToList(){
    if(!this.form.product_detail.product_id){
      this.db.presentAlert('Error','Please select product first!');
      return;
    }
    if(!this.form.product_detail.order_qty || this.form.product_detail.order_qty < 0){
      this.db.presentAlert('Error','Please enter a valid quantity!');
      return;
    }
    
    let i = this.form.orderItem.findIndex(r=>r.product_id === this.form.product_detail.product_id)
    if(i != -1){
      this.form.orderItem.splice(i,1)
    }
    
    this.form.orderItem.push(this.form.product_detail);
    
    
    this.form.basicData.net_amt = 0;
    this.form.basicData.total_qty = 0;
    this.form.basicData.total_amt = 0;
    this.form.basicData.tax_amt = 0;
    this.form.basicData.discount_amt = 0;
    this.form.orderItem.map(r=>{
      this.form.basicData.total_qty += r.order_qty
      this.form.basicData.net_amt += r.net_amt
      this.form.basicData.total_amt += r.total_amt
      this.form.basicData.tax_amt += r.tax_amt
      this.form.basicData.discount_amt += r.discount_amt
      
      this.form.basicData.total_qty = parseFloat(this.form.basicData.total_qty).toFixed(2)
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt).toFixed(2)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2)
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt).toFixed(2)
      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt).toFixed(2)
    });
    this.form.basicData.total_item = this.form.orderItem.length;
    
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null
    };
  }
  addConfirmation(data:any){
    swal({
      title: "Are you sure?",
      text: "You want place this purchase!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((submit) => {        
      if (submit) {
      this.onSubmit();
      }
    });
  }
  onSubmit(){
    console.log(this.form);
    let reqData = this.form.basicData
    reqData.orderItem = this.form.orderItem
    reqData.total_item = this.form.orderItem.length
    this.db.presentLoader();
    this.db.postReq(reqData,'po/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  removeItem(i){
    this.form.orderItem.splice(i,1)
  }
}
