import { IfStmt } from '@angular/compiler';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from '../_services/database.service';
import { CustomEventsService } from '../_services/custom-events.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  private zoneChangeSubject = new Subject<any>();
  onZoneChange(event: any) {
    this.zoneChangeSubject.next(event);
  }
  project_type:any;
  form:any={};
  sales_user:any;
  userData = JSON.parse(localStorage.getItem('hmdUserData'));
  masterSearch:any
  constructor( private render: Renderer2 ,
    private router : Router,
    private _renderer: Renderer2,
    public db : DatabaseService,
    public event:CustomEventsService) { 
      // this.db.setLoginData();
      // console.log('userData',this.db.userLoginData);
      // console.log('routername', this.userData);
      
      this.project_type = this.router.url == '/dnm_mangement/distribution-detail'?'distributor':this.router.url == '/ddm_mangement/direct-dealer-detail'?'direct-dealer':this.router.url == '/dm_mangement/dealer-detail'?'dealer':'';
    }
    
    status:boolean = false
    
    ngOnInit() {
      this.form.sales_user = [this.userData.id];
      console.log('form -->', this.form);
      this.getAssignedUserData('');
      this.zoneChangeSubject.pipe(debounceTime(300)).subscribe((event) => {
        this.getAssignedUserData(event);
      });
      this.getZone();
      this.userRole = JSON.parse(localStorage.getItem('hmdUserData')) 
    }
    
    userRole:any ='';
    onChange(project_type:any)
    {
      this.project_type = project_type;
      console.log('projectType',this.project_type);
    }
    
    toggleHeader() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        // this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    
    openNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'nav-active');
      }
      else {
        this.render.removeClass(document.body, 'nav-active');
      }
    }
    
    
    printDoc(){
      const popupWin = window.open('/document-history/', '_blank', 'top=0,left=0,height=100%,width=auto');
    } 
    searchItemClickhandler(data){
      if(data.table_name.includes('customer')){
        if(data.is_lead == '1')this.db.router.navigateByUrl('dr-detail/'+data.encrypt_id)
        else this.db.router.navigateByUrl('dealer-basic/'+data.encrypt_id)
      }else{
        if(data.for_sales_user == '1')this.db.router.navigateByUrl('sales-user-detail/'+data.encrypt_id);
        if(data.for_system_user == '1')this.db.router.navigateByUrl('users-edit/'+data.encrypt_id);
      }
      this.searchData = [];
      this.masterSearch=null;
    }
    searchData:any=[];
    getData(){
      this.searchData = [];
      if(!this.masterSearch){
        return;
      }
      {
        let reqData = {
          "filter" : {
            "search" : this.masterSearch
          }
        }
        
        this.db.presentLoader();
        
        this.db.postReq(reqData,'master/search').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.searchData = resp['result'].data
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errHandler(err);
        })
        
      }
    }
    logout() {
      localStorage.removeItem('hmdUserData');
      localStorage.removeItem('sessionToken');
      this.router.navigate(['']);
      this.db.sessionToken = '';
    }
    fontSize:any = localStorage.getItem('fontSize') ? localStorage.getItem('fontSize') : '100%';
    zoom(level){
      this._renderer.setStyle(document.body, 'zoom',level);
      localStorage.setItem('fontSize',level);
      this.fontSize = level;
    }
    
    salesData:any=[];
    zone:any = [];
    filteredSalesData:any=[];
    assignedUserData : any;
    getAssignedUserData(data) {
      const zone = data.length ? data.map(item => item.zone) : ''
      console.log('zone',zone);
      
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
        },
        "zone" :zone
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,"get_junior/assignedUserList").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        if(resp['status']=='success'){
          this.salesData = resp['data'];
          this.selectAllForDropdownItems(this.salesData);
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        console.log('err',err);
      })
    }

    getZone() {
      
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
        }
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,"master/zone_list").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        if(resp['status']=='success'){
          this.zone = resp['result'].data;
          this.selectAllForDropdownItems(this.zone);
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        console.log('err',err);
      })
    }
    
    
    selectAllForDropdownItems(items: any[]) {
      let allSelect = items => {
        items.forEach(element => {
          element['selectedAllGroup'] = 'selectedAllGroup';
        });
      };
      allSelect(items);
    }
    
    
    setAssignedUser(event:any) {
      console.log('event --> setAssignedUser', event);
      console.log('userData -->', this.userData);
      this.db.getAssignedUserData = this.form.sales_user;
      console.log('db -->', this.db.getAssignedUserData);
     
      if(this.db.router.url.includes('travel-list')) {
        setTimeout(() => {
          this.event.publish('refresh-travel-plan-list',{})
        }, 500);
      }

      if(this.db.router.url.includes('attendance-list')) {
        setTimeout(() => {
          this.event.publish('refresh-attendance-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('checkin-list')) {
        setTimeout(() => {
          this.event.publish('refresh-checkin-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('leave-list')) {
        setTimeout(() => {
          this.event.publish('refresh-leave-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('expense-list')) {
        setTimeout(() => {
          this.event.publish('refresh-expense-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('event-plan-list')) {
        setTimeout(() => {
          this.event.publish('refresh-event-plan-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('followup-list')) {
        setTimeout(() => {
          this.event.publish('refresh-followup-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('dr-list')) {
        setTimeout(() => {
          this.event.publish('refresh-dr-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('visit-report')) {
        setTimeout(() => {
          this.event.publish('refresh-visit-report',{})
        }, 500);
      }
      if(this.db.router.url.includes('complaint-module-list')) {
        setTimeout(() => {
          this.event.publish('refresh-complaint-module-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('order-list')) {
        setTimeout(() => {
          this.event.publish('refresh-order-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('us-checkin-list')) {
        setTimeout(() => {
          this.event.publish('refresh-us-checkin-list',{})
        }, 500);
      }

      if(this.db.router.url.includes('sales-user-list')) {
        setTimeout(() => {
          this.event.publish('refresh-sales-user-list',{})
        }, 500);
      }
      if(this.db.router.url.includes('travel-vs-visit')) {
        setTimeout(() => {
          this.event.publish('refresh-travel-vs-visit',{})
        }, 500);
      }
      // if(this.db.router.url.includes('travel-plan-report')) {
      //   setTimeout(() => {
      //     this.event.publish('refresh-travel-plan-report',{})
      //   }, 500);
      // }
    }
    
  }
  