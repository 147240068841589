import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { TravelStatusModelComponent } from '../status-modal/status-model.component';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-travel-detail',
  templateUrl: './travel-detail.component.html'
})
export class TravelDetailComponent implements OnInit {
  showLoading:any=false;
  status:boolean = false
  
  constructor(public dialog : MatDialog, private render: Renderer2,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService, public event:CustomEventsService) { 
    event.subscribe('refresh-travel-detail', (data: any) => {
      this.getDetail(this.activatedroute.snapshot.params.id)
      console.log('refresh');
      
    })
  }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
    
  }

  monthDataArray: any[] = [];
  teamMember: any[] = [];
  userDetail: any = {};
  data: any = {};

  getDetail(id: any) {
    this.showLoading = true;
    this.db.presentLoader();

    this.db.postReq({ 'travel_plan_id': id }, "travel_plan/detail").subscribe(async (resp: any) => {
      this.db.dismissLoader(); 

      if (resp['status'] === 'success') {
        this.data = resp['data'];
        this.monthDataArray = this.data.detail;
        this.userDetail = this.data.detail[0];


        for (const r of this.monthDataArray) {
          r.working_with = '';
          
          const matchingTeamData = resp['data'].teamData.filter(x => x.travel_plan_id === r.id);
          
          if (matchingTeamData.length > 0) {
              r.working_with = matchingTeamData.map(x => x.user_name).join(', ');
          }
      }
      

        this.showLoading = false; 
      } else {
        this.showLoading = false;

        if (resp['message'] === 'Invalid token') {
          this.db.sessionExpire();
          return;
        }

        this.db.presentAlert(resp['status'], resp['message']);
      }
    }, (err: any) => {
      // Handle request errors
      this.db.errHandler(err);
    });
  }


  backClicked() {
    this._location.back();
  }
  
  
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, 'toggle-active-tlp');
    }
    else {
      // this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  selectAll(event){
    this.data.detail.map(r=>{
      if(event.checked == true){
        r.checked = true;
      }else{
        r.checked = false
      }
    })
  }
  
  statusArray:any= [];
  travelPlanStatusUpdate(data:any) {  
    data.map(row=>{
      if(row.checked) this.statusArray.push({id:row.id});
    })
    
    if(!this.statusArray.length){
      return;
    }
    
    console.log("data",this.statusArray)
    return
    // this.data.basicDetail.encrypt_id =  this.activatedroute.snapshot.params.id
    const dialogRef = this.dialog.open(TravelStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: this.statusArray
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.event.publish('refresh-travel-detail',{});
      this.statusArray = [];
      this.data = [];
      this.getDetail(this.activatedroute.snapshot.params.id);
    });
  }
  
  approvalArray:any = []
  sendForApproval(data) {
    
    this.approvalArray = [];
    data.map(row=>{
      if(row.checked) this.approvalArray.push({id:row.id,user_id:row.user_id,user_name:row.user_name,user_email:row.email,designation:row.designation,user_mobile:row.mobile_no,travel_day:row.travel_day,travel_date : row.travel_from,state_name : row.state_name, district_name : row.district_name,city : row.city,area : row.area,description : row.description,working_with : row.working_with});
    })
    let reqData = {
      data : this.approvalArray
    }
    console.log(this.approvalArray,data);
    
    if(!this.approvalArray.length){
      this.db.presentAlert('error','Please select at least 1 Travel detail')
      return;
    }
    
    swal({
      title: "Are you sure?",
      text: "You Want To Send This For Approval",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    
    .then((willDelete) => {        
      if (willDelete) {
        this.showLoading = true;
        this.db.presentLoader();
        this.db.postReq(reqData,'travel_plan/send_for_approval').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal("Sent for Approval", {
              icon: "success",
            });
            this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
            this.getDetail(this.activatedroute.snapshot.params.id);
            this.showLoading = false;
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.showLoading = false;
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Request Not Sent!");
      }
    });
    
  }
}
