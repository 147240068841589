import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-bulk-user-assign-model',
  templateUrl: './bulk-user-assign-model.component.html',
})
export class BulkUserAssignModelComponent implements OnInit {

  @ViewChild('select', {static: false}) select : MatSelect
  constructor(public actRoute : ActivatedRoute,@Inject(MAT_DIALOG_DATA) public drData: any, public db:DatabaseService) { }

  ngOnInit() {
    this.getData();
    console.log("drData",this.drData)
    this.formsInit(this.drData);

  } 

  form: FormGroup;
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      sales_user: [data.sales_user ? data.sales_user : null ,[Validators.required]],
    })
    console.log(this.form.value);

  }

  data:any=[];
    start:any=0;
    limit:any=100;
    filter:any={};
    getData(){
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'master/sales_user_list').subscribe(resp=>{
        // this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

  allSelected=false;
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    
    // console.log(this.form.working_state);
    
  }

  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData:any = {};
    reqData = this.form.value;
    reqData.dr_ids = this.drData;
    console.log("reqData",reqData);
    // return;
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/bulk_assign_sales_user').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }


}
