import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { RemarkModelComponent } from 'src/app/remark-model/remark-model.component';
import * as moment from 'moment';
import { GiftStatusModelComponent } from 'src/app/redeem-req/gift-status-model/status-model.component';
import { RedeemRequestStatusModelComponent } from 'src/app/redeem-req/redeem-status-model/status-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { ExpenseStatusModelComponent } from 'src/app/expense/status-modal/status-model.component';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';
import { LeaveStatusModelComponent } from 'src/app/leave-status/status-model.component';

@Component({
  selector: 'app-approval-process-list',
  templateUrl: './approval-process-list.component.html',
})
export class ApprovalProcessListComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false
  showLoading:any=false;
  checkModulePermission:any = {};

  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public event:CustomEventsService,
    public activatedroute:ActivatedRoute,
    ) {  
      this.checkModulePermission = this.db.loggedInPermission(this.activatedroute.snapshot.routeConfig.path,0);
      console.log('checkModulePermission -->',this.activatedroute.snapshot.routeConfig.path);
      
      this.event.subscribe('refresh-travel-plan-list', (data: any) => {
        // this.type =this.activatedroute.snapshot.params.type
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        // this.getCounters();
        // console.log('refresh');
      });
      
    }
    
    
    ngOnInit() {
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    type:any='Travel Plan';
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      if (this.type == 'Leave') {
        this.getLeaveApprovalData();
      } else {
        this.dataNotFound = false;
        this.reqSent = false;
        this.filter.status = this.tabActive;
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.filter,
          type : 'Travel Plan',
          getAssignedUserData : this.db.getAssignedUserData
        }
        this.db.presentLoader();
        this.db.postReq(reqData,'approval_process/travel_list').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.data = resp['result'].data;
            if(!this.data.length) this.dataNotFound = true;
            this.totalLength = resp['result'].totalCount;
            this.totalPages = Math.ceil(this.totalLength / this.limit);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
        }
    }
    
    getLeaveApprovalData() {
      this.dataNotFound = false;
        this.reqSent = false;
        this.filter.status = this.tabActive;
        let reqData = {
          limit : this.limit,
          start : this.start,
          filter : this.filter,
          type : 'Leave',
          getAssignedUserData : this.db.getAssignedUserData
        }
        this.db.presentLoader();
        this.db.postReq(reqData,'approval_process/leave_list').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.data = resp['result'].data;
            if(!this.data.length) this.dataNotFound = true;
            this.totalLength = resp['result'].totalCount;
            this.totalPages = Math.ceil(this.totalLength / this.limit);
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          
        })
    }
    
    counters:any=[];
    getCounters(){
      
      this.db.presentLoader();
      let reqData = {
        getAssignedUserData : this.db.getAssignedUserData,
        type : 'Travel Plan',
      }

      this.db.postReq(reqData,'approval_process/travel_list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.counters= resp['result'].data
          // })
          console.log('this.counters',this.counters);
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

    getLeaveCounters(){
      
      this.db.presentLoader();
      let reqData = {
        getAssignedUserData : this.db.getAssignedUserData,
        type : 'Travel Plan',
      }

      this.db.postReq(reqData,'approval_process/travel_list/count').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.counters= resp['result'].data
          // })
          console.log('this.counters',this.counters);
          this.getData();
          
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
        if(this.totalLength == (this.start+this.data.length)) return;
        
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        // this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }

    selectAll(event){
      this.data.map(r=>{
        if(event.checked == true){
          r.checked = true;
        }else{
          r.checked = false
        }
      })
    }

    statusArray:any= [];
  ApprovalPlanStatusUpdate(data: any) {
    // Re-initialize statusArray
    this.statusArray = [];
  
    // Filter checked rows and push relevant details into statusArray
    data.forEach(row => {
      if (row.checked) {
        this.statusArray.push({
          id: row.id,
          leave_id : row.leave_id,
          start_date : row.leave_start_date,
          end_date : row.leave_end_date,
          sender_id: row.sender_id,
          sender_name: row.sender_name,
          sender_email: row.sender_email,
          sender_designation: row.sender_designation,
          manager_id: row.manager_id,
          manager_name: row.manager_name,
          manager_designation: row.manager_designation,
          manager_email: row.manager_email,
          level : row.level,
        });
      }
    });

    if(!this.statusArray.length){
      this.db.presentAlert('error','Please select at least 1 Leave detail')
      return;
    }
  
    // If no rows are checked, return early
    if (!this.statusArray.length) {
      return;
    }
  
    // Open the dialog with the filtered data
    const dialogRef = this.dialog.open(LeaveStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: this.statusArray
    });
  
    // Handle the dialog close event
    dialogRef.afterClosed().subscribe(result => {
      console.log('result',result);
        console.log('result',result);
    });
  }
}
