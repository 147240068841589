import { NgModule } from '@angular/core';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
    declarations: [
        
    ],
    imports: 
    [
        MatSelectFilterModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatExpansionModule,
        MatSliderModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatBottomSheetModule,
        MatDividerModule
    ],
    
    exports:
    [
        MatSelectFilterModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatExpansionModule,
        MatSliderModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        MatDividerModule,
    ],
})



export class  MaterialModule { }