import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-status-model',
  templateUrl: './status-model.component.html'
})
export class TravelStatusModelComponent implements OnInit {
  submit:any;
  constructor(public db:DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _location: Location) { }
  // data:any = this.db.redeemRequestData
  ngOnInit() {
    console.log('data--->',this.data);
    
    this.formsInit(this.data);
  }
  todayDate:any
  
  form: FormGroup;
  formsInit(data:any) {
    // console.log('data',data);
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.form = this.db.formBuilder.group({
      status: [data.status ? data.status : '',[Validators.required]],
      // travel_plan_id: [data.id ? data.id : null ,[]],
      remark: [data.remark ? data.remark : null ,[]],
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  onSubmit(){
    
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    reqData.approval_data = this.data;
    reqData.sender_id  = this.data[0].sender_id
    // console.log('travel-detail',reqData);
    // return

    this.db.presentLoader();
    this.db.postReq(reqData,'approval_process/update_status').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
