import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-contact-edit-model',
  templateUrl: './contact-edit-model.component.html'
})
export class ContactEditModelComponent implements OnInit {
  
  constructor(public matdialog:MatDialog, @Inject(MAT_DIALOG_DATA) public data :any ,public db :DatabaseService) { }
  
  ngOnInit() {
    console.log(this.data);
    this.formsInit();
  }
  form: FormGroup;
  
  formsInit() {
    this.form = this.db.formBuilder.group({
      email: [ this.data.email ? this.data.email : '' , [Validators.required]],
      mobile: [ this.data.mobile ? this.data.mobile : '' ,[Validators.required,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
      designation: [ this.data.designation ? this.data.designation : '' ,[]],
      department: [ this.data.department ? this.data.department : '' ,[]],
      name: [ this.data.name ? this.data.name : '' , [Validators.required]],
      contact_id: [this.data.id ? this.data.id : null],
      customer_id: [this.data.customer_id ? this.data.customer_id : null],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['email'].markAsTouched();
      this.form.controls['name'].markAsTouched();
      this.form.controls['designation'].markAsTouched();
      this.form.controls['mobile'].markAsTouched();
      return
    }
    console.log(this.form.value);
    let reqdata 
    if(this.data.contact_id){
      reqdata = this.form.value
    }else{
      reqdata = {
        customer_id:this.data.customer_id,
        contactDetail:[this.form.value]
      } 
    }
    // console.log(this.data.id);
    // return
    this.db.presentLoader();
    this.db.postReq(reqdata, this.data.id ? 'dis_network/update_contact_detail' : 'dis_network/add_contact_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.matdialog.closeAll()        
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
}
