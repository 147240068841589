import { Component, OnInit, Input } from '@angular/core';
import { HierarchyService } from '../_services/hierarchy.service';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  styleUrls: ['./hierarchy-tree.component.scss']
})

export class HierarchyTreeComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() isRoot: boolean = false;

  constructor(private hierarchyService: HierarchyService, public db: DatabaseService, public actRoute: ActivatedRoute) { }

  async ngOnInit() {
    // if (this.isRoot && (!this.data || this.data.length === 0)) {
    // this.hierarchyService.getHierarchyData().subscribe(data => {
    //   this.data = data;
    //   console.log('this.data',this.data);
    // });
    // }
    if (this.isRoot && (!this.data || this.data.length === 0)) {
      const payload = { /* your payload data */ };
      this.hierarchyService.getHierarchyDataV2(payload).subscribe(data => {
        if (Array.isArray(data)) {
          this.data = data;
          console.log('data -->', this.data);
        } else {
          console.error('Data is not an array:', data);
        }
      });
    }
  }

  toggleChildren(node: any) {
    if (!node.showChildren) {
      node.showChildren = true;
      if (!node.children || node.children.length === 0) {
        this.hierarchyService.getChildrenData(node.id).subscribe(children => {
          node.children = children;
        });
      }
    } else {
      node.showChildren = false;
    }
  }

  updateNode(node: any) {

    // Update the node name with the new value from the input field
    if (node.newName && node.newName.trim() !== '') {
      node.level = node.newName;
      node.newName = '';
      this.db.presentLoader();
      this.db.postReq(node, 'user_heirarchy/update_level').subscribe(resp => {
        this.db.dismissLoader();
        if (resp['status'] == 'success') {
          this.db.successAlert(resp['status'], resp['message']);
        } else {
          if (resp['message'] == 'Invalid token') {
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'], resp['message'])
        }
      })
    } else {
      alert('Please enter a valid name');
    }    
  }
}

// if (node.newName && node.newName.trim() !== '') {
//   node.name = node.newName;
//   node.newName = '';
// } else {
//   alert('Please enter a valid name');
// }