import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-expense-add',
  templateUrl: './expense-add.component.html'
})
export class ExpenseAddComponent implements OnInit {
  data:any=[];
  autoFocus: boolean = true;
  food_total:any
  constructor(public db:DatabaseService,private _location: Location) { }
  form:any={
    travel_entitlement:{},
    travel_entitlement_array:[],
    hotel:{},
    hotel_array:[],
    food:{},
    food_array:[],
    local_conveyance:{},
    local_conveyance_array:[],
    food_total:0,
    hotel_total:0,
    local_conveyance_total:0,
    entitlement_total:0,
    total:0,
    fileArr:[],
    misc:{},
    misc_array:[],
    misc_total:0,
    miscellaneous_expense:0,
    stationary:0,
    mobile_charges:0,
    email_charges:0,
    remark:''
  };
  ngOnInit() {
    // this.getData();
    this.getsalesData();
    this.form.local_conveyance = {}
    this.form.hotel = {}
    this.form.food = {}
    this.form.travel_entitlement = {}
    this.form.misc = {}
    
    this.form.travel_entitlement.departure_date = moment().format('YYYY-MM-DD')
    this.form.travel_entitlement.arrival_date = moment().format('YYYY-MM-DD')
    this.form.hotel.checkin_date = moment().format('YYYY-MM-DD')
    this.form.hotel.checkout_date = moment().format('YYYY-MM-DD')
    this.form.food.date = moment().format('YYYY-MM-DD')
    this.form.local_conveyance.date = moment().format('YYYY-MM-DD')
    this.form.misc.date = moment().format('YYYY-MM-DD')  }
    
    backClicked() {
      this._location.back();
    }
    
    add_to_list(key){
      if(key == 'travel_entitlement'){
        if(!this.form.travel_entitlement.departure_time  || !this.form.travel_entitlement.departure_date || !this.form.travel_entitlement.departure_station || !this.form.travel_entitlement.arrival_date || !this.form.travel_entitlement.arrival_time || !this.form.travel_entitlement.arrival_station){
          this.db.presentAlert('Alert','Please fill all the required(*) details!');
          return;
        }
      }

      this.form.travel_entitlement.departure_date = moment(this.form.travel_entitlement.departure_date).format('YYYY-MM-DD')
      this.form.travel_entitlement.arrival_date = moment(this.form.travel_entitlement.departure_date).format('YYYY-MM-DD')
      
      if(!this.form[key + '_array'].length) this.form[key + '_array'] = [];
      this.form[key].travel_total = 
      parseInt(this.form[key].amount || 0) + 
      parseInt(this.form[key].h_q || 0) + 
      parseInt(this.form[key].ex_h_q || 0) + 
      parseInt(this.form[key].out_station || 0) + 
      parseInt(this.form[key].resi_to_station || 0) + 
      parseInt(this.form[key].station_to_resi || 0);
      
      this.form[key + '_array'].push(this.form[key]);
      console.log('this.form[key',this.form[key + '_array']);
      
      if(!this.form[key+'_total']) this.form[key+'_total'] = 0
      this.form[key+'_total'] = parseInt(this.form[key+'_total']) + parseInt(this.form[key].amount)
      if(!this.form['entitlement_total']) this.form['entitlement_total'] = 0;
      
      this.form['entitlement_total'] += this.form[key].travel_total;

      this.form[key] = {}
    }

    deleteContact(row,index){
      console.log(index);
      swal({
        title: "Are you sure?",
        text: "You want to delete this!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {  
          this.form['travel_entitlement_array'].splice(index, 1);
        } else {
          swal("Your data is safe!");
        }
      });
    }

    miscellaneous_expense: number = 0;
    stationary: number = 0;
    mobile_charges: number = 0;
    email_charges: number = 0;
    addExtra:boolean = false
    addExtraExpense() {
      this.addExtra = true
      // Ensure the values are numbers, in case they are undefined or empty strings
      this.miscellaneous_expense = Number(this.form.miscellaneous_expense) || 0;
      this.stationary = Number(this.form.stationary) || 0;
      this.mobile_charges = Number(this.form.mobile_charges) || 0;
      this.email_charges = Number(this.form.email_charges) || 0;
      
      // Calculate the total by summing all the numeric values
      this.form.total = this.miscellaneous_expense +
                        this.stationary +
                        this.mobile_charges +
                        this.email_charges +
                        Number(this.form.entitlement_total) || 0;
    
      console.log('this.form.total', this.form.total);
    }
    
    step = 0;
    async addFile($event){
      console.log($event);
      this.form.image_path_loading = true;
      
      var file = $event.target.files[0];
      var reader = new FileReader();
      var image
      reader.onloadend = function () {
        image = reader.result;
      }
      setTimeout(() => {
        console.log(image);
        let reqData = {base64:image}
        this.db.presentLoader();
        this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.form.image_path = resp['response'].fileName;
            this.form.image_path_loading = false;
            this.form.fileArr = [...this.form.fileArr,resp['response']];
            
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
      }, 100);
      
      reader.readAsDataURL(file);
      
    }
    setStep(index: number) {
      this.step = index;
    }
    
    nextStep() {
      this.step++;
    }
    
    prevStep() {
      this.step--;
    }
    remove(i){
      this.form.fileArr.splice(i,1);
    }
    salesData:any=[];
    filteredSalesData:any=[];
    getsalesData(){
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
          // "cust_type_id" :this.form.value.dr_type.id
        }
      }
      this.db.presentLoader();
      this.db.postReq(reqData,"get_all/assignedUserList").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        // this.db.requestSent = true;
        if(resp['status']=='success'){
          this.salesData = resp['data'];
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        // this.db.errorAlert(err); 
      })  
    }
    onSubmit(data:any){
      
      if( !this.form.travel_entitlement_array.length){
        this.db.presentAlert('Alert','Please add Travel items');
        return;
      }
      if( this.addExtra == false){
        this.db.presentAlert('Alert','Please add Extra Expense Item');
        return;
      }
      // if(!this.form.fileArr.length){
      //   this.db.presentAlert('Alert','Please add attachments');
      //   return;
      // }
      let reqData = this.form
      console.log(reqData);
      
      this.db.presentLoader();
      this.db.postReq(reqData,'expense/add').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.db.successAlert(resp['status'],resp['message']);
          this.backClicked();
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        this.db.errDismissLoader();
      })
      
    }
  }
  