import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { BrandDetailModelComponent } from '../brand-detail-model/brand-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
@Component({
  selector: 'app-travel-plan-report',
  templateUrl: './travel-plan-report.component.html',
})
export class TravelPlanReportComponent implements OnInit {
  type:any = 'all';
  data:any=[];
  dataNotFound=true
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public event:CustomEventsService,
    ) {
      this.event.subscribe('refresh-travel-plan-report', (data: any) => {
        this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
        this.getData();
        console.log('refresh');
      });
     }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.generateMonthYearArray();
      this.getAssignedUserData();
      console.log(this); 
    }
    
    
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={
      user_name : JSON.parse(localStorage.getItem('hmdUserData')).name
    };
    

    monthYear = '';
    sales_user = '';
    month:any;
    year:any;
    travelPlanListDataKey:any;
    travelPlanListData:any=[];
    showLoading:any=false;
    getData(){
      this.showLoading = true;
      console.log('travelPlanListData', this.travelPlanListData);      

      if (this.monthYear == '') {
        this.db.presentAlert('Please Select Month','You can Download report once you selected the month');
        return;
      }
      
      var monthMap = {
        'Jan': 1, 
        'Feb': 2,
        'Mar': 3,
        'Apr': 4,
        'May': 5,
        'Jun': 6,
        'Jul': 7,
        'Aug': 8,
        'Sep': 9,
        'Oct': 10,
        'Nov': 11,
        'Dec': 12
      };
      
      var dateComponents = this.monthYear.split(' ');
      this.month = monthMap[dateComponents[0]];
      this.year = parseInt(dateComponents[1]);
      
      let reqData = {
        year : this.year,
        month : this.month,
        filter : {
          status : 'All'
        },
        user_id : this.sales_user,
        type:this.type,
        getAssignedUserData : this.db.getAssignedUserData
      };
      
      this.db.presentLoader();
      this.db.postReq(reqData,'travel_plan/list/report').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          console.log('---.',resp['result'].data);

          this.travelPlanListData = resp['result'].data;

          this.travelPlanListDataKey  = Array.from(
            this.travelPlanListData.reduce((keys, obj) => {
              Object.keys(obj).forEach(key => keys.add(key));
              return keys;
            }, new Set<string>()));
            
            console.log('this.travelPlanListDataKey', this.travelPlanListDataKey);
            this.showLoading = false;
            this.dataNotFound = false;
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.showLoading = false;
          this.db.presentAlert('Error','Error occured')
        })
      }
      pagination(action){
        if(action == 'pageNo'){
          if(this.pageNo > 0 && this.pageNo <=this.totalPages){
            this.start = this.limit*(this.pageNo-1)
          }else{
            this.pageNo = Math.ceil(this.totalLength / this.data.length);
          }
        }
        else if(action=='next'){
          if(this.totalLength == (this.start+this.data.length)) return;
          
          this.start = this.start+this.limit
          this.pageNo ++ ;
        }else{
          if(this.pageNo == 1) return
          this.start = this.start-this.limit
          this.pageNo -- ;
        }
        this.getData();
        this.tempPageNo = this.pageNo
      }
      refresh(){
        this.start = 0;
        this.limit = 50;
        this.filter = {};
        this.getData();
      }
      delete(data){
        swal({
          title: "Are you sure?",
          text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        })
        .then((willDelete) => {        
          if (willDelete) {
            this.db.presentLoader();
            data.status = data.status == 0 ? 1 : 0;
            data.role_id = data.id;
            this.db.postReq(data,'master/system_user/update').subscribe(resp=>{
              if(resp['status'] == 'success'){
                swal(data.status == 0 ? "Inactive!" : "Active", {
                  icon: "success",
                });
              }else{
                if(resp['message']=='Invalid token'){
                  this.db.sessionExpire();
                  return;
                }
                this.db.presentAlert(resp['status'],resp['message'])
              }
            })
            
          } else {
            swal("Your data is safe!");
          }
        });
      }


      backClicked() {
        this._location.back();
      }
      
      monthYearResult:any = [];
      generateMonthYearArray() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); 
        const currentYear = currentDate.getFullYear();
        this.monthYearResult = [];
        
        for (let i = -2; i < 4; i++) {
          let monthIndex = (currentMonth + i) % 12;
          let year = currentYear + Math.floor((currentMonth + i) / 12);
          // console.log(monthIndex,'---',year);
          
          if (monthIndex < 0) {
            monthIndex += 12;
            // year--;
          }
          const monthYearString = `${months[monthIndex]} ${year}`;
          this.monthYearResult.push(monthYearString);
          console.log('monthYear',this.monthYearResult);
          
        }
      }

      getExcelReport(){
      
        if (this.monthYear == '') {
          this.db.presentAlert('Please Select Month','You can Download report once you selected the month');
          return
        }
        
        var monthMap = {
          'Jan': 1,
          'Feb': 2,
          'Mar': 3,
          'Apr': 4,
          'May': 5,
          'Jun': 6,
          'Jul': 7,
          'Aug': 8,
          'Sep': 9,
          'Oct': 10,
          'Nov': 11,
          'Dec': 12
        };
        
        var dateComponents = this.monthYear.split(' ');
        this.month = monthMap[dateComponents[0]];
        this.year = parseInt(dateComponents[1]);
        let reqData = {
          year : this.year,
          month : this.month,
          filter : {
            status : 'All'
          },
          type:this.type,
          getAssignedUserData : this.db.getAssignedUserData
        }
        // console.log('reqData',reqData);
        // return
        
        this.db.presentLoader();
        this.db.postReq(reqData,'travel_plan/list/excel').subscribe(resp=>{
          this.reqSent = true;
          if(resp['status'] == 'success'){
            this.showLoading = false;
            this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.showLoading = false;
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.presentAlert('Error','Error Occured')
          this.showLoading = false;
        })
      }
      

    salesData:any=[];
    
    getAssignedUserData() {
      
      let reqData =  {
        "limit" :100000,
        "start" : 0,
        "filter" : {
        }
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,"get_junior/assignedUserList").subscribe(async (resp:any) => {
        await this.db.dismissLoader();
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        if(resp['status']=='success'){
          this.salesData = resp['data'];
        }else{
          this.db.presentAlert(resp['status'],resp['message']);
        }
      },async err => {
        console.log('err',err);
      })
    }
    
    }
    