import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { BrandDetailModelComponent } from '../brand-detail-model/brand-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';

@Component({
  selector: 'app-checkin-count-reports',
  templateUrl: './checkin-count-reports.component.html',
})
export class CheckinCountReportsComponent implements OnInit {

  data:any=[];
  dataNotFound=true
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.generateMonthYearArray();
      this.db.getStates('');
      // this.getData()
      console.log(this); 
    }
    
    
    todayDate :any
    showLoading:any=false;
    monthYearResult:any = [];
    generateMonthYearArray() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); 
      const currentYear = currentDate.getFullYear();
      this.monthYearResult = [];
      
      for (let i = -6; i < 2; i++) {
        let monthIndex = (currentMonth + i) % 12;
        let year = currentYear + Math.floor((currentMonth + i) / 12);
        // console.log(monthIndex,'---',year);
        
        if (monthIndex < 0) {
          monthIndex += 12;
          // year--;
        }
        const monthYearString = `${months[monthIndex]} ${year}`;
        this.monthYearResult.push(monthYearString);
        console.log('monthYear',this.monthYearResult);
        
      }
    }

    start:any=0;
    limit:any=5000;
    monthYear = '';
    month:any;
    year:any;
    monthDates:any=[];
    filter:any={ };
    totalCount:any = 0
    verticalCount: any = {};
    
    getData(){
      console.log(this.monthYear);
      console.log(this.filter.date_to);
      console.log(this.filter.date_from);
      
      if (this.monthYear == '' && (this.filter.date_to == undefined || this.filter.date_from == undefined)) {
        this.db.presentAlert('Please Select Month or date from date to','You can view report once you selected the month or date');
        return;
      }
      var monthMap = {
        'Jan': '01', 
        'Feb': '02',
        'Mar': '03',
        'Apr': '04',
        'May': '05',
        'Jun': '06',
        'Jul': '07',
        'Aug': '08',
        'Sep': '09',
        'Oct': '10',
        'Nov': '11',
        'Dec': '12'
      };
      
      var dateComponents = this.monthYear.split(' ');
      this.month = monthMap[dateComponents[0]];
      this.year = parseInt(dateComponents[1]);

      this.showLoading = true;
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        year : this.year,
        month : this.month,
        type:'month',
        getAssignedUserData : this.db.getAssignedUserData
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'attendance_monthly_checkin/list/report').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          this.monthDates = resp['result'].monthDates
          this.totalCount = resp['result'].totalCount;
          this.verticalCount = resp['result'].verticalCount;
          this.showLoading = false
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
          this.showLoading = false;
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

    downloadExcel(){
      let reqData = {
        year : this.year,
        month : this.month,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'checkin/count/report/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }

}
