import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import * as moment from 'moment';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import swal from 'sweetalert';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  
})
export class UserAddComponent implements OnInit {
  @ViewChild('select', {static: false}) select : MatSelect
  showLoading:any=false;
  
  constructor(private _location: Location,public actRoute : ActivatedRoute,public db:DatabaseService) { }
  form:any={};
  // banks:any=[];
  filteredbanks:any=[]
  ngOnInit() {
    this.getRoleData();
    this.getGradeData();
    this.db.getCountry();
    this.getStates();
    this.db.getZone('','','','');
    this.getBankList();
    this.getModuleData();
    this.getSalesUserList();
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }else{
      this.getRmData(null)
    }
    // if 
    this.userRole = JSON.parse(localStorage.getItem('hmdUserData')) 
    // console.log('this.userRole',this.userRole);
    
  } 

  // approvalDetailDetail:any = []
  userRole:any ='';
  sales_user : any =''
  searchState:any
  searchDistrict:any
  states:any=[]
  getStates(){
    this.db.postReq({filter:{state_name:this.searchState}},'master/state_list').subscribe(async resp=>{
      this.states= resp['result'].data
    })
  }
  districts:any=[]
  getDistricts(event){
    console.log("vhj",event)
    let statesArr=[];
    event.map(r=>{
      statesArr.push("'"+r.state_name+"'");
    })
    // console.log("statesArr.push",statesArr);
    this.db.postReq({filter:{state_name_array:statesArr,district_name:this.searchDistrict}},'master/district_list').subscribe(async resp=>{
      this.districts = resp['result'].data
    })  
  }
  
  getBankList() {
    let reqData = {};
    this.db.presentLoader();
    this.db.postReq(reqData,'bank/list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.filteredbanks = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
  }
  
  getSalesUserList() {
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'travel_plan/sales_user').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.sales_user = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
  }
  
  
  allSelected=false;
  toggleAllStateSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    
    console.log(this.form.working_state);
    
  }
  
  
  getDetail(id){
    this.db.presentLoader();
    
    this.showLoading = true;
    
    this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.form = resp['data'].detail;
        console.log("====>",this.form)
        this.form.user_id = this.form.id
        this.form.date_of_joining = moment(this.form.date_of_joining).format('YYYY-MM-DD')
        this.form.date_of_marriage = moment(this.form.date_of_marriage).format('YYYY-MM-DD')
        this.form.date_of_left = moment(this.form.date_of_left).format('YYYY-MM-DD')
        this.form.date_of_birth = moment(this.form.date_of_birth).format('YYYY-MM-DD')
        this.form.mobile = this.form.mobile_no
        this.form.role = parseInt(this.form.role)
        this.form.status = this.form.status ? this.form.status.toString():'';
        this.form.track_location = this.form.track_location.toString() ;
        this.form.app_user = this.form.app_user.toString() ;
        // this.form.zone = this.form.zone.split(',');
        this.form.working_state = [];
        this.form.working_district = [];
        this.form.rmData = [];

        this.moduleData.map(row=>{
          resp['data'].permissionData.map(r=>{
            if(row.id == r.module_id){
              row.add = r.add == 1 ? true : false ;
              row.edit = r.edit == 1 ? true : false ;
              row.delete = r.delete == 1 ? true : false ;
              row.view = r.view == 1 ? true : false ;
              row.permission_id = r.id;
            }
          })
        })
        
        if (this.form.zone) {
          this.form.zone = this.form.zone.split(',');
        }
        
        let approvelData = []
        resp['data'].approvalInfo.map(r=> {
          this.sales_user.map (x => {
            if (x.id == r.approver_id) {
              approvelData.push({type:r.type,level:r.level,sales_user:x})
            }
          })
        });

        this.form.approvalDetailDetail = approvelData;
        
        console.log("this.form.zone",this.form.zone)
        setTimeout(async () => {
          console.log(this.form);
          var workingStateArray = [];
          var workingDistrictArray = [];
          var disStateArray = [];
          if(resp['data'].assignedState.length){
            resp['data'].assignedState.map(r=>{
              workingStateArray.push(r.state_name);
              disStateArray.push({'state_name' : r.state_name})
            })
            this.form.working_state = workingStateArray;
            console.log("this.form.working_state",this.form.working_state);
          }
          
          if(resp['data'].assignedDistrict.length){
            resp['data'].assignedDistrict.map(r=>{
              workingDistrictArray.push(r.district_name);
            })
            console.log("this.form.working_district",this.form.working_district);
          }
          this.form.working_district = workingDistrictArray;
            console.log("this.form.working_district",this.form.working_district);
          
          var reportingManagerUserIds = [];
          if(resp['data'].reportingUser.length){
            resp['data'].reportingUser.map(r=>{
              console.log("rm-->",r)
              reportingManagerUserIds.push(r.assigned_user_id);
            })
            this.form.rmData = reportingManagerUserIds;
            console.log('rmData  reportingUser length -->',this.form.rmData);
          }

          this.getDistricts(disStateArray);
          
          if(this.form.role){
            this.getRoleData();
          }
          if(this.form.country){
            this.db.getStates(this.form.country);
          }
          if(this.form.state_name){
            this.db.getDistricts(this.form.state_name);
          }
          if(this.form.state_name && this.form.district_name){
            this.db.getCity(this.form.state_name,this.form.district_name);
          }
          if(this.form.state_name && this.form.district_name && this.form.city){
            this.db.getArea(this.form.state_name,this.form.district_name,this.form.city);
          }
          await this.getRmData(resp['data'].reportingUser.length ? resp['data'].reportingUser : null)
          
          this.showLoading = false;
          console.log('rmData  here now s settimeout ---->', this.form);
          
        }, 1000 * 1);
        
        console.log('rmData  here now ---->', this.form);
        
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.showLoading = false;
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  
  changeListener($event,type): void {
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form[type] = resp['response'].fileName;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  formSubmitted:any=false;
  userData:any={};
  onSubmit(myForm:any)
  {
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    
    if(!emailpattern.test(this.form.email)){
      this.db.presentAlert('Error','Please enter a valid email!');
      return;
    }
    var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    if(!mobilepattern.test(this.form.mobile)){
      this.db.presentAlert('Error','Please enter a valid mobile!');
      return;
    }
    let moduleData = this.moduleData;
    moduleData.map(r=>{
      r.add = r.add ? 1 : 0 ;
      r.edit = r.edit ? 1 : 0 ;
      r.view = r.view ? 1 : 0 ;
      r.delete = r.delete ? 1 : 0 ;
      r.module_id = r.id
    })
    this.form.zone = this.form.zone.toString();
    
    
    this.formSubmitted = true;
    var reportingManager = [];
    if (this.form.rmData) {
      if(this.form.rmData.length){
        this.form.rmData.forEach(element => {
          console.log("===>",element,this.rmData)
          var username = this.rmData.filter(x=>x.id == element)[0].name;
          console.log('username',username);
          reportingManager.push({id : element, 'name' : username});
        });
      }
    }
    
    this.form.rmData = reportingManager.length ? reportingManager : [];
    let reqData = this.form
    reqData.userPermission = this.moduleData
    console.log(reqData);
    // return;

    this.form.date_of_birth = this.form.date_of_birth == 'Invalid date' ? '0000-00-00' : this.form.date_of_birth;
    this.form.date_of_joining = this.form.date_of_joining == 'Invalid date' ? '0000-00-00' : this.form.date_of_joining;
    this.form.date_of_marriage = this.form.date_of_marriage == 'Invalid date' ? '0000-00-00' : this.form.date_of_marriage;
    this.form.date_of_left = this.form.date_of_left == 'Invalid date' ? '0000-00-00' : this.form.date_of_left;
    
    this.db.presentLoader();
    this.db.postReq(reqData,this.actRoute.snapshot.params.id ? 'master/sales_user/update' : 'master/sales_user/add').subscribe(resp=>{
      this.formSubmitted = false;
      if(resp['status'] == 'success'){
        this.db.successAlert('Success',resp['message']);
        this.backClicked();
        // myForm.resetForm();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
  
  
  rmData:any=[];
  searchRmData:any = [];
  filteredrmData:any=[];
  getRmData(assignedUserIds:any){
    console.time();
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/reporting_sales_user').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        this.rmData = resp['result'].data;
        this.filteredrmData = resp['result'].data;
        await resp['result'].data.map(r=>{
          this.searchRmData.push(r.name);
          r.rmLabelName = r.name + ' - '+ r.emp_code + ' - '+ r.designation;
        })
        this.filteredrmData.searchRmData = this.searchRmData
        console.log('this.form.rmData --->',this.form.rmData);
        console.log('this.form.rmData --->',this.form.rmData);
        console.log('this.form.rmData --->',typeof(this.form.rmData));
        
        console.log('rmData  ->>', this.rmData, this.form);
        console.time();
        
      }
      else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  roleData:any=[];
  // dataNotFound=false
  getRoleData(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {
        "for_sales_user": 1
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/role_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.roleData = resp['result'].data;
        console.log("roledata==>",this.roleData);
        if(this.actRoute.snapshot.params.id){
          this.roleData.map(r=>{
            if(r.id == this.form.role){
              this.form.role = r;
            }
          })
        }
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  productDivisionData:any=[];
  
  gradeData:any=[];
  // dataNotFound=false
  getGradeData(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/grade').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.gradeData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  moduleData:any=[];
  getModuleData(){
    this.db.presentLoader();
    
    this.db.postReq({},'master/module_list').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.moduleData = resp['result'].data
        this.moduleData.map(r=>{
          r.add = false;
          r.edit = false;
          r.delete = false;
          r.view = false;
        })
        
        
        // if(this.actRoute.snapshot.params.id){
        //   this.getDetail(this.actRoute.snapshot.params.id)
        // }
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }

  selectAll:any={};
  selectAllHandler(control,event){
    console.log(event);
    this.moduleData.map(r=>{
      r[control] = event;
    })
  }
  
  backClicked() {
    this._location.back();
  }

  addApprovalDetail() {
    // Initialize the array only if it hasn't been initialized yet
    if (!this.form.approvalDetailDetail) {
      this.form.approvalDetailDetail = [];
    }
  
    // Add the new approval detail to the array
    this.form.approvalDetailDetail.push({
      type: this.form.type,
      sales_user: this.form.sales_user,
      level: this.form.level,
    });
  
    // Clear the input fields after adding the contact
    this.form.type = '';
    this.form.sales_user = '';
    this.form.level = '';
  }
  

  deleteContact(row,index){
    console.log(index);
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {  
        this.form.approvalDetailDetail = this.form.approvalDetailDetail.filter((r,i)=> i != index )
      } else {
        swal("Your data is safe!");
      }
    });
    
  }
}
