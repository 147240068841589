import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import * as moment from 'moment';
import { FormBuilder, FormControl } from '@angular/forms';

// import { type } from 'os';

@Component({
  selector: 'app-dr-add',
  templateUrl: './dr-add.component.html',
  // styleUrls: ['./dr-add.component.scss']
})
export class DrAddComponent implements OnInit {
  type:any = this.activatedroute.snapshot.params.type
  typeName:any = this.type == 1 ? 'Distributor' : 'Customer'
  showLoading:any=false;
  
  constructor(public events:CustomEventsService,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDrType()
    this.db.getCountry();
    // this.db.states('');
    // this.getBrands();
    // this.getUsers();
    this.getBanks();
    this.db.getZone('','','','');
    this.getSpeciality();
    this.getCategory();

    if(this.activatedroute.snapshot.params.id){
      this.getDetail(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
    } 
    
  }

  specialityName:any = []
  getDetail(id){
    this.db.presentLoader();
    this.showLoading = true;
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        // console.log("===>",resp['data'])
        this.formsInit(resp['data']);
        resp['data'].speciality.map(r=> {
          this.specialityName.push(r.name) 
        }) 
        var category = '';
        var brand = [];
        var product = [];
        resp['data'].productDetail.map(r=> {
          category = r.product_category
          brand.push(r.brand) 
          product.push(r.product_id) 
        })        
        console.log('product',product);
        
        const uniqueBrand = [...new Set(brand)];
        this.form.controls['brand'].setValue(uniqueBrand)
        this.form.controls['product_category'].setValue(category)
        this.form.controls['product_name'].setValue(product)
        this.form.controls['doctors_specialization'].setValue(this.specialityName)

        this.getBrand(category);
        this.getProducts(uniqueBrand);
        this.db.getStates(resp['data'].basicDetail.country);
        this.db.getDistricts(resp['data'].basicDetail.state_name);
        this.db.getCity(resp['data'].basicDetail.state_name,resp['data'].basicDetail.district_name);
        this.showLoading = false;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    // if (this.form.value.area) {
    //   this.form.value.area = this.form.value.area.split(',');
    // }
    
  }

  getDrType(){
    this.db.postReq({},'dis_network/type_list').subscribe(resp=>{
      this.db.drType = resp['result'].data
      this.db.drType.map(r=>r.id = r.id.toString())      
    })
  }
  
  form: FormGroup;
  
  formsInit(data:any) {
    console.log('this.activatedroute.snapshot.params.type == 2',this.activatedroute.snapshot.params.type == 2);
    
    this.form = this.db.formBuilder.group({
      company_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.company_name : null   , [Validators.required]],
      first_name : [this.activatedroute.snapshot.params.id ? data.basicDetail.first_name : null   , []],
      cust_code: [  this.activatedroute.snapshot.params.id ? data.basicDetail.cust_code : null  , []],
      old_cust_code: [  this.activatedroute.snapshot.params.id ? data.basicDetail.old_cust_code : null  , []],
      gst_no: [  this.activatedroute.snapshot.params.id ? data.basicDetail.gst_no : null  , [Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/),Validators.minLength(15)]],
      cust_type_id: [  this.activatedroute.snapshot.params.id ? data.basicDetail.cust_type_id : null  , [Validators.required]],
      status: [  "1"  , [Validators.required]],
      email: [  this.activatedroute.snapshot.params.id ? data.basicDetail.email : null  , [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
      mobile: [  this.activatedroute.snapshot.params.id ? data.basicDetail.mobile : null  ,this.activatedroute.snapshot.params.type == 2 ? [Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/),Validators.required]: []],
      landline_no: [  null , []],
      transporter: [ this.activatedroute.snapshot.params.id ? data.basicDetail.transporter_name : null  , []],
      billing_status: [this.activatedroute.snapshot.params.id ? data.basicDetail.billing_status : null  , []],
      // username: [  null , [Validators.required]],
      // password: [  null , [Validators.required]],
      // credit_day: [  null , [Validators.required]],
      street: [  this.activatedroute.snapshot.params.id ? data.basicDetail.street : null  , []],
      country: [  this.activatedroute.snapshot.params.id ? data.basicDetail.country : null  , []],
      state_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.state_name : null  , []],
      district_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.district_name : null  , []],
      city: [  this.activatedroute.snapshot.params.id ? data.basicDetail.city : null  , []],
      zone: [  this.activatedroute.snapshot.params.id ? data.basicDetail.zone : null  , []],
      area: [ this.activatedroute.snapshot.params.id ? data.basicDetail.area ? data.basicDetail.area.split(',') : null : [] , []],
      beat: [  null , []],
      pincode: [  this.activatedroute.snapshot.params.id ? data.basicDetail.pincode : null  , []],
      cust_id: [  this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null ],
      cp_email: [this.activatedroute.snapshot.params.id ? data.basicDetail.cp_email : null ],
      cp_designation: [this.activatedroute.snapshot.params.id ? data.basicDetail.cp_designation : null ],
      cp_mobile: [this.activatedroute.snapshot.params.id ? data.basicDetail.cp_mobile : null ],
      cp_name: [this.activatedroute.snapshot.params.id ? data.basicDetail.cp_name : null ],
      cp_department : [this.activatedroute.snapshot.params.id ? data.basicDetail.cp_department : null ],
      contactDetail: [this.activatedroute.snapshot.params.id ? data.contactDetail : []  , []],
      // contactDetail : [[]],
      // brandDetail: [[] , [Validators.required]],
      bank_name:[ this.activatedroute.snapshot.params.id ? data.basicDetail.bank_name : null  , []],
      account_number:[ this.activatedroute.snapshot.params.id ? data.basicDetail.account_number : null  , []],
      account_holder_name:[ this.activatedroute.snapshot.params.id ? data.basicDetail.account_holder_name : null  , []],
      ifsc_code:[ this.activatedroute.snapshot.params.id ? data.basicDetail.ifsc_code : null  , []],
      ltd_llp:[ this.activatedroute.snapshot.params.id ? data.basicDetail.ltd_llp : null  , []],
      security_amount:[ this.activatedroute.snapshot.params.id ? data.basicDetail.security_amount : null  , []],
      credit_limit:[ this.activatedroute.snapshot.params.id ? data.basicDetail.credit_limit : null  , []],
      credit_period:[ this.activatedroute.snapshot.params.id ? data.basicDetail.credit_period : null  , []],
      blank_cheque_recieve_date:[ this.activatedroute.snapshot.params.id ? moment(data.basicDetail.blank_cheque_recieve_date).format('YYYY-MM-DD'): null  , []],
      cheque_number:[ this.activatedroute.snapshot.params.id ? data.basicDetail.cheque_number : null  , []],
      amount:[ this.activatedroute.snapshot.params.id ? data.basicDetail.amount : null  , []],
      cheque_date:[ this.activatedroute.snapshot.params.id ? moment(data.basicDetail.cheque_date).format('YYYY-MM-DD'): null  , []],
      security_cheque_detail:[ this.activatedroute.snapshot.params.id ? data.basicDetail.security_cheque_detail : null  , []],
      cheque_image: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_image ? data.basicDetail.cheque_image.replace(this.db.s3path,'') : null ,[]],
      chequebook: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_book ? data.basicDetail.cheque_book.replace(this.db.s3path,'') : null ,[]],
      c_image: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_image ? data.basicDetail.cheque_image.replace(this.db.s3path,'') : null ,[]],
      cb_image: [this.activatedroute.snapshot.params.id && data.basicDetail.cheque_book ? data.basicDetail.cheque_book.replace(this.db.s3path,'') : null ,[]],
      assignedUser: [[] , [Validators.required]],
      doc_type  : [ 'Adhar Card',],      //Validators.required
      doc_no : [ null,],       //Validators.required
      doc_image : [ null,],//Validators.required
      doc_image_1 : [ null,],//Validators.required
      pan_image: [null,],//Validators.required
      pan_no: [null,],//Validators.required
      image : [ null],
      image_1 : [ null],
      image_3 : [ this.activatedroute.snapshot.params.id ? data.basicDetail.profile_image : null],
      image_4 : [ null],
      drug_licence : [ this.activatedroute.snapshot.params.id ? data.basicDetail.drug_licence : null],
      doctors_qualification : [ this.activatedroute.snapshot.params.id ? data.basicDetail.doctors_qualification : null],
      doctors_specialization : [ this.activatedroute.snapshot.params.id ? data.speciality : null],
      date_of_birth : [ this.activatedroute.snapshot.params.id ? data.basicDetail.date_of_birth : null],
      product_category : [ this.activatedroute.snapshot.params.id ? data.basicDetail.product_category : null],
      brand : [ this.activatedroute.snapshot.params.id ? data.basicDetail.brand : null],
      product_name : [ this.activatedroute.snapshot.params.id ? data.basicDetail.product_name : null],
    })
    
    this.getUsers(data);
   
    // this.db.getArea(this.form.value.state_name,this.form.value.district_name,this.form.value.city);
    if(!this.activatedroute.snapshot.params.id){ this.form.controls['cust_type_id'].setValue(this.type)}

    data.documentDetail.map(r=>{
      // if(r.doc_type.toLowerCase().includes('company')){
      //   this.form.controls['company_doc'].setValue(r.doc_filename.replace(this.db.s3path,''));
      // }else 
      if(r.doc_type.toLowerCase().includes('pan')){
        this.form.controls['pan_no'].setValue(r.doc_type_value);
        this.form.controls['pan_image'].setValue(r.doc_filename.replace(this.db.s3path,''));
        this.form.controls['image_4'].setValue(r.doc_filename.replace(this.db.s3path,''));
      }else{
        this.form.controls['doc_no'].setValue(r.doc_type_value);
        this.form.controls['doc_type'].setValue(r.doc_type);
        this.form.controls['doc_image'].setValue(r.doc_filename.replace(this.db.s3path,''));
        this.form.controls['image'].setValue(r.doc_filename.replace(this.db.s3path,''));
        this.form.controls['doc_image_1'].setValue(r.doc_filename_1.replace(this.db.s3path,''));
        this.form.controls['image_1'].setValue(r.doc_filename_1.replace(this.db.s3path,''));
      }
    })
  }

  

  changeListener($event): void {
      
    // console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      // console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['doc_image'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  changeListener_PAN($event): void {
    
    // console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      // console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['pan_image'].setValue(resp['response'].fileName);
          console.log('pan_image-->',resp['response'].fileName)
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  changeListener_cheque($event): void {
    
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['cheque_image'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }

  changeListener_chequebook($event): void {
    
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          console.log('filename',resp['response'].fileName)
          this.form.controls['chequebook'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  
  changeListener_1($event): void {
    
    // console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      // console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
          this.form.controls['doc_image_1'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }

  onSubmit(){
    let reqData:any = {};
    reqData.basicDetail = this.form.value
    this.db.drType.map (r=> {
      if (r.id == this.form.value.cust_type_id) {
        reqData.basicDetail.cust_type = r.name
      }
    })
    console.log(this.form);
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    if (this.form.value.area) {
      this.form.value.area = this.form.value.area.toString();
    }
    
    
    this.form.value.blank_cheque_recieve_date = this.form.value.blank_cheque_recieve_date == 'Invalid date' ? '0000-00-00' : this.form.value.blank_cheque_recieve_date;
    this.form.value.cheque_date = this.form.value.cheque_date == 'Invalid date' ? '0000-00-00' : this.form.value.cheque_date;
    reqData.basicDetail = this.form.value
    // reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id = reqData.basicDetail.cust_type_id)[0].name
    reqData.contactDetail = this.form.value.contactDetail;
    reqData.doctors_specialization = this.form.value.doctors_specialization;

    // reqData.contactDetail = this.form.value.contactDetail;
    // reqData.assignedUser = [{user_id:this.form.value.assignedUser.id,user_name:this.form.value.assignedUser.name
    // }];
    // reqData.brandDetail = this.form.value.brandDetail;
    // console.log("reqData",reqData);
    // return
    
    // if(this.form.value.contactDetail.length==0){
    //   this.db.presentAlert('Error','Please Enter atleast one contact detail')
    //   return;
    // }
    // return

    var productArray = [];
    this.productsData.map(r=> {
      reqData.basicDetail.product_name.map(x=> {
        if (r.id == x) {
          productArray.push(r)
        }
      })
    })
    reqData.productArray = productArray;
    console.log(productArray);
    
    reqData.status = this.form.value.status
    this.db.presentLoader();
    this.db.postReq(reqData,this.activatedroute.snapshot.params.id  ? 'dis_network/update_network' : 'dis_network/add_network').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        // this.events.publish('tabActive',{})
        this.backClicked();
        this.db.router.navigateByUrl('dr-list/'+this.form.value.cust_type_id,{replaceUrl:true})
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  addContactDetails(){

    console.log(this.form.value,this.form.value.cp_name ,this.form.value.cp_designation ,this.form.value.cp_email ,this.form.value.cp_mobile)
    if(!this.form.value.cp_name){
      this.db.presentAlert('Error','Please fill all the required details to continue!');
      return;
    }
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    console.log(emailpattern.test(this.form.value.cp_email));
    
    // if(!emailpattern.test(this.form.value.cp_email)){
    //   this.db.presentAlert('Error','Please enter a valid email!');
    //   return;
    // }
    // var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    // if(!mobilepattern.test(this.form.value.cp_mobile)){
    //   this.db.presentAlert('Error','Please enter a valid mobile!');
    //   return;
    // }  
    console.log(this.form.value.cp_designation)
    console.log(this.form.value.cp_name)
    console.log(this.form.value.cp_email)
    console.log(this.form.value.cp_mobile)
    console.log(this.form.value.cp_department)
    this.form.controls['contactDetail'].setValue([...this.form.value.contactDetail,{name:this.form.value.cp_name ,email:this.form.value.cp_email,designation:this.form.value.cp_designation,mobile:this.form.value.cp_mobile,department:this.form.value.cp_department}])
    this.form.controls['cp_name'].setValue(null);
    this.form.controls['cp_designation'].setValue(null);
    this.form.controls['cp_mobile'].setValue(null);
    this.form.controls['cp_email'].setValue(null);
    this.form.controls['cp_department'].setValue(null);
    console.log(this.form)
  }
  // get myArrayControl() {
  //   return this.form.get('contactDetail') as FormControl;
  // }
  deleteContact(row,index){
    console.log(index);
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {  
        this.form.controls['contactDetail'].setValue(this.form.value.contactDetail.filter((r,i)=> i != index ))
      } else {
        swal("Your data is safe!");
      }
    });
    // }
    
    
  }

  usersData:any=[]
    filteredUsersData:any=[]
    getUsers(data:any={}){
      let reqData = {
        limit : 30000,
        start : 0,
        filter : {}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.usersData = resp['result'].data;
          this.filteredUsersData = this.usersData

          console.log(data.salesUserDetail);
          if(data.salesUserDetail){
            data.salesUserDetail.map(r=>{
              let i = this.usersData.findIndex(item => item.id == r.user_id)
              if(i!= -1){
                this.form.controls['assignedUser'].setValue([...this.form.value.assignedUser,this.usersData[i]]);
              }
            })
          }
          

        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

  banks:any=[];
  filteredbanks:any=[]
  getBanks(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'bank/list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.banks = resp['result'].data;
        this.filteredbanks = this.banks;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  postalData:any=[];
  pincodeHandler(){
    let reqData = {
      filter:{pincode:this.form.value.pincode}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/getDetailByPincode').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.postalData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  specialityData:any=[];
  getSpeciality(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/speciality').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.specialityData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  selectPostal(data){
    this.form.controls['state_name'].setValue(data.state_name);
    this.form.controls['district_name'].setValue(data.district_name);
    this.form.controls['city'].setValue(data.city);
    this.form.controls['area'].setValue(data.area);
    this.form.controls['zone'].setValue(data.zone);
    // this.form.controls['beat'].setValue(data.beat);
  }

  categoryData:any = [];
  getCategory(){
    
    this.db.postReq({start:0,limit:1000,filter:{}},'master/product_category').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.categoryData = resp['result'].data        ;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    })
  }

  brand:any = [];
  getBrand(category_name) {
    let reqData = {
      limit : 1000000,
      start : 0,
      filter : {},
      category_name : category_name
    }
    this.db.presentLoader();
    this.db.postReq(reqData,"master/category/assigned_brand").subscribe(async (resp:any) => {
      await this.db.dismissLoader();
      if(resp['status']=='success'){
        this.brand = resp['result'].data;
        console.log(this.brand);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message']);
      }
    })
  }

  productsData:any=[]
  getProducts(brand){
    console.log('brand',brand);
    
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {},
      brand : brand
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/product_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        this.productsData = resp['result'].data;
        // this.filteredProductsData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
}
