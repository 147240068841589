import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { TravelStatusModelComponent } from 'src/app/travel/status-modal/status-model.component';
import swal from 'sweetalert';

@Component({
  selector: 'app-approval-process-detail',
  templateUrl: './approval-process-detail.component.html',
})
export class ApprovalProcessDetailComponent implements OnInit {

  showLoading:any=false;
  status:boolean = false
  
  constructor(public dialog : MatDialog, private render: Renderer2,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService, public event:CustomEventsService) { 
    event.subscribe('refresh-travel-detail', (data: any) => {
      this.getDetail(this.activatedroute.snapshot.params.id,this.activatedroute.snapshot.params.date)
      console.log('refresh');
      
    })
  }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id,this.activatedroute.snapshot.params.date)
    
  }
  userDetail:any = {};
  data:any={};
  getDetail(id,date){
    this.showLoading = true;
    this.db.presentLoader();
    
    this.db.postReq({'sender_encrypt_id':id,'travel_date' : date},'approval_process/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        // console.log(resp['data']);
        this.showLoading = false;
      }else{
        this.showLoading = false;
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, 'toggle-active-tlp');
    }
    else {
      // // this.render.removeClass(document.body, 'toggle-active-tlp');
    }
  }
  selectAll(event){
    this.data.map(r=>{
      if(event.checked == true){
        r.checked = true;
      }else{
        r.checked = false
      }
    })
  }

  statusArray:any= [];
  ApprovalPlanStatusUpdate(data: any) {
    // Ensure data is an array
    // console.log('data',data);
    // return
    if (!Array.isArray(data)) {
      console.error('Invalid data format: expected an array');
      return;
    }
  
    // Re-initialize statusArray
    this.statusArray = [];
  
    // Filter checked rows and push relevant details into statusArray
    data.forEach(row => {
      if (row.checked) {
        this.statusArray.push({
          id: row.id,
          sender_id: row.sender_id,
          sender_name: row.sender_name,
          sender_email: row.sender_email,
          sender_designation: row.sender_designation,
          manager_id: row.manager_id,
          manager_name: row.manager_name,
          manager_designation: row.manager_designation,
          manager_email: row.manager_email,
          travel_day: row.travel_day,
          travel_date: row.travel_date,
          travel_plan_id: row.travel_plan_id,
          level : row.level,
          state_name : row.state_name,
          district_name : row.district_name,
          city : row.city,
          area : row.area,
          description : row.description,
          working_with : row.working_with
        });
      }
    });
  
    // If no rows are checked, return early
    if (!this.statusArray.length) {
      return;
    }
  
    // Open the dialog with the filtered data
    const dialogRef = this.dialog.open(TravelStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: this.statusArray
    });
  
    // Handle the dialog close event
    dialogRef.afterClosed().subscribe(result => {
      console.log('result',result);
        console.log('result',result);
        this.getDetail(this.activatedroute.snapshot.params.id, this.activatedroute.snapshot.params.date);
    });
  }
  
}